<template>
  <div
    class="s-form-col"
    :class="{
      'small-left':smallLeft,
      'small-right':smallRight,
    }">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-form-col {
    max-width: 100%;
    width:100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    .s-field {
      margin-top: 0;
    }

    + .s-form-col {
      margin-top: $form-vertical-spacing;
    }

    &.small-left {
      grid-template-columns: 3fr 7fr;
    }

    &.small-right {
      grid-template-columns: 7fr 3fr;
    }
  }
</style>
<script>
  export default {
    props: {
      smallLeft: {
        type: Boolean,
        default: false,
      },
      smallRight: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
