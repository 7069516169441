import Vue from 'vue'
import InProgressIndicator from '../components/InProgressIndicator'
import Timer from '../components/dashboard/Timer'
import Addtime from '../assets/custom_icons/Addtime'
import Stop from '../assets/custom_icons/Stopcircle'
import StickyTimer from "~/components/dashboard/StickyTimer"

Vue.component('in-progress-indicator', InProgressIndicator)
Vue.component('timer', Timer)
Vue.component('addtime', Addtime)
Vue.component('stop', Stop)
Vue.component('sticky-timer', StickyTimer)
