<template>
  <button
    class="s-btn"
    :class="{
      'is-small':isSmall,
      'is-large':isLarge,
      'is-primary':isPrimary,
      'is-danger':isDanger,
      'is-fullwidth':isFullwidth
    }"
    :disabled="isDisabled"
    :type="nativeType"
    v-on="$listeners">
    <span :class="{
      'has-i-edit':hasIEdit,
      'has-i-add':hasIAdd,
      'has-i-add-time':hasIAddTime,
      'has-i-check':hasICheck,
      'has-i-trash':hasITrash,
      'has-i-pdf':hasIPdf,
    }"></span>
    <slot></slot>
  </button>
</template>
<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-btn {
    @include button-base;
    @include button-color($button-default);
    height: $control-height-button;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 $btn-side-padding;
    white-space: nowrap; //stops btn text going over 2 lines or more

    &:focus {
      outline: none;
    }

    &:active {
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      text-decoration: none;
    }

    + .s-btn {
      margin-left: 24px;
    }

    &.is-small {
      height: $control-height-small;
      padding: 0 $btn-side-padding-sm;
      span:before {
        padding-top: .25rem !important;
      }
    }

    &.is-large {
      height: $control-height-large;
      padding: 0 $btn-side-padding-lg;
    }

    &.is-primary {
      @include button-color($button-primary);
    }

    &.is-danger {
      @include button-color($button-danger);
    }

    &.is-fullwidth {
      width: 100%;
      justify-content: center;
    }

    span {
      padding: 0!important;
      &.has-i-edit {
        @include icon-left-btn($padding: $btn-side-padding+1rem, $padding-icon: 2.3rem, $icon: $icomoon-edit, $icon-size: .9rem);

        &.is-small {
          @include icon-left-btn($padding: 1.4rem, $padding-icon: .4rem, $icon: $icomoon-edit, $icon-size: .75rem);
        }
      }

      &.has-i-add {
        @include icon-left-btn($padding: $btn-side-padding+0.9rem, $padding-icon: 2.4rem, $icon: $icomoon-add, $icon-size: .9rem);

        &.is-small {
          @include icon-left-btn($padding: 1.3rem, $padding-icon: .4rem, $icon: $icomoon-add, $icon-size: .75rem);
        }
      }

      &.has-i-add-time {
        @include icon-left-btn($padding: $btn-side-padding+1.4rem, $padding-icon: 2.5rem, $icon: $icomoon-plus-square, $icon-size: 1.2rem);

        &.is-small {
          @include icon-left-btn($padding: 1.65rem, $padding-icon: .4rem, $icon: $icomoon-plus-square, $icon-size: 1rem);
        }
      }

      &.has-i-check {
        @include icon-left-btn($padding: $btn-side-padding+0.9rem, $padding-icon: 2.3rem, $icon: $icomoon-check, $icon-size: 1rem);

        &.is-small {
          @include icon-left-btn($padding: 1.4rem, $padding-icon: .3rem, $icon: $icomoon-check, $icon-size: .9rem);
        }
      }

      &.has-i-trash {
        @include icon-left-btn($padding: $btn-side-padding+1rem, $padding-icon: 2.25rem, $icon: $icomoon-trash, $icon-size: 1rem);

        &.is-small {
          @include icon-left-btn($padding: 1.4rem, $padding-icon: .3rem, $icon: $icomoon-trash, $icon-size: .9rem);
        }
      }

      &.has-i-pdf {
        @include icon-left-btn($padding: $btn-side-padding+1rem, $padding-icon: 2.25rem, $icon: $icomoon-pdf, $icon-size: 1rem);

        &.is-small {
          @include icon-left-btn($padding: 1.4rem, $padding-icon: .3rem, $icon: $icomoon-pdf, $icon-size: .9rem);
        }
      }
      &:before {
        position: relative !important;
        left: unset !important;
        top: unset !important;
        transform: none !important;
        padding: 0 !important;
        padding-right: .5rem !important;
      }
    }
  }
</style>

<script>
  export default {
    props: {
      isSmall: {
        type: Boolean,
        default: false,
      },
      isLarge: {
        type: Boolean,
        default: false,
      },
      isPrimary: {
        type: Boolean,
        default: false,
      },
      isDanger: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isFullwidth: {
        type: Boolean,
        default: false,
      },
      hasIEdit: {
        type: Boolean,
        default: false,
      },
      hasIAdd: {
        type: Boolean,
        default: false,
      },
      hasIAddTime: {
        type: Boolean,
        default: false,
      },
      hasICheck: {
        type: Boolean,
        default: false,
      },
      hasITrash: {
        type: Boolean,
        default: false,
      },
      hasIPdf: {
        type: Boolean,
        default: false,
      },
      nativeType: {
        type: String,
        default: 'button',
        validator: (value) => {
          return [
            'button',
            'submit',
            'reset'
          ].indexOf(value) >= 0
        }
      },
    }
  }
</script>
