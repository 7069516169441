<template>
  <button
    class="s-i-btn"
    :class="{
      'has-i-trash':isDelete,
      'has-i-edit':isEdit,
      'has-i-eye':isEye,
      'has-i-add':isAdd,
      'has-i-add-time':isAddTime,
      'has-i-play':isPlay,
      'has-i-pause':isPause,
      'has-i-chevron-left':isLeft,
      'has-i-chevron-right':isRight,
      'is-grey':isGrey,
      'has-i-check':hasICheck,
      'has-i-close':hasIClose,
    }"
    :disabled="isDisabled"
    @click="$emit('click', $event)">
    <slot></slot>
  </button>
</template>
<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-i-btn {
    text-decoration: none;
    border: none;
    padding: 0;
    outline: none;
    background: none;
    cursor: pointer;

    &:active {
      box-shadow: none;
    }

    &:disabled {
      cursor: initial;

      &:hover:after {
        background-color: transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
      z-index: -1;
      bottom: -10px;
      left: -10px;
      right: -10px;
      top: -10px;
      background: none;
      border-radius: 20%;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform, opacity;
    }

    &:hover {
      text-decoration: none;

      &:after {
        background-color: darken($smoke, 5%);
        border: none;
        box-shadow: none;
        opacity: 1;
        z-index: 1;
        transform: scale(.6);
      }

      &:before {
        z-index: 2;
      }
    }

    &.has-i-chevron-left {
      @include icon-standalone-button($icon: $icomoon-chevron-left, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }
    &.has-i-close {
      @include icon-standalone-button($icon: $icomoon-close, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-chevron-right {
      @include icon-standalone-button($icon: $icomoon-chevron-right, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-trash {
      @include icon-standalone-button($icon: $icomoon-trash, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);

      &:before {
        color: $red;
      }
    }

    &.has-i-check {
      @include icon-standalone-button($icon: $icomoon-check, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-add {
      @include icon-standalone-button($icon: $icomoon-add, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-edit {
      @include icon-standalone-button($icon: $icomoon-edit, $size: 1.1rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-eye {
      @include icon-standalone-button($icon: $icomoon-eye, $size: 1.2rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-add-time {
      @include icon-standalone-button($icon: $icomoon-add-time, $size: 1.2rem, $width: $action-btn-width, $height: $action-btn-height);
    }

    &.has-i-pause {
      @include icon-standalone-button($icon: $icomoon-pause-circle, $size: 1.2rem, $width: $action-btn-width, $height: $action-btn-height);

      &:before {
        color: $timer-green;
        animation: timer-pulse 1s infinite;
        border-radius: 50%;
        background: $white;
      }
    }

    &.has-i-play {
      @include icon-standalone-button($icon: $icomoon-play-circle, $size: 1.2rem, $width: $action-btn-width, $height: $action-btn-height);

      &:before {
        color: $timer-orange;
        border-radius: 50%;
        background: $white;
      }
    }

    &.has-i-play,
    &.has-i-pause {
      &.is-grey {
        &:before {
          color: $slate;
        }
      }
    }

    &.has-i-add,
    &.has-i-edit,
    &.has-i-eye,
    &.has-i-add-time {
      &:before {
        color: $slate;
      }

      &:hover {
        &:before {
          color: $charcoal;
        }
      }
    }

  }
</style>
<script>
  export default {
    props: {
      isDelete: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      isEye: {
        type: Boolean,
        default: false,
      },
      isAdd: {
        type: Boolean,
        default: false,
      },
      isAddTime: {
        type: Boolean,
        default: false,
      },
      isPlay: {
        type: Boolean,
        default: false,
      },
      isPause: {
        type: Boolean,
        default: false,
      },
      isLeft: {
        type: Boolean,
        default: false,
      },
      isRight: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isGrey: {
        type: Boolean,
        default: false,
      },
      hasICheck: {
        type: Boolean,
        default: false,
      },
      hasIClose: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
