<template>
  <div class="notification-list">
    <transition name="fade">
      <ul>
        <s-notification
          v-for="(item, index) in list"
          :key="index"
          :class="[
            {
              'auto-close': item.autoClose,
            },
            item.type,
          ]"
          :is-closable="!item.autoClose"
          @remove="closeNotification(index)"
          @remove-after-delay="closeNotificationAfterDelay(index)"
        >
          <div style="display: flex; gap: 18px">
            <SuccessIcon v-if="item.type === 'is-success'" style="flex-shrink: 0" />
            <WarningIcon v-if="item.type === 'is-warning'" style="flex-shrink: 0" />
            <ErrorIcon v-if="item.type === 'is-danger'" style="flex-shrink: 0" />
            <div>
              <p class="notif-title">{{ item.message }}</p>
              <span v-if="item.subtitle" class="notif-description" v-html="item.subtitle"></span>
            </div>
          </div>
        </s-notification>
      </ul>
    </transition>
  </div>
</template>
<script>
import SNotification from '~/components/utils/SNotification'
import { EventBus } from './event-bus'
import SuccessIcon from '~/components/utils/SuccessIcon.vue'
import WarningIcon from '~/components/utils/WarningIcon.vue'

export default {
  components: {
    WarningIcon,
    SuccessIcon,
    SNotification,
  },
  data() {
    return {
      list: [],
      timeout: null,
    }
  },
  created() {
    EventBus.$on('obj', (value) => {
      value.id = this.list.length + 1
      this.list.push(value)
    })
    EventBus.$on('clear', () => {
      this.list = []
    })
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    closeNotification(index) {
      this.list.splice(index, 1)
    },
    closeNotificationAfterDelay() {
      this.timeout = setTimeout(() => {
        this.list.splice(0, 1)
      }, 5000)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~assets/scss/imports.scss';

.notification-list {
  position: fixed;
  bottom: 64px;
  left: 64px;
  width: 560px;
  z-index: 9999;

  .s-notification {
    box-shadow: 0px 6px 14px rgba(107, 114, 128, 0.12), 0px 10px 32px rgba(107, 114, 128, 0.1);

    + .s-notification {
      margin-top: 10px;
    }

    .notif-title {
      font-size: 24px;
      font-weight: 600;
      margin-top: 4px;
    }

    .notif-description {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      margin-top: 8px;
      color: $grey-extra-5;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
