<template>
  <div class="s-select" :class="{'has-append':append}">
    <div class="s-select-wrapper">
      <input ref="input" v-model="searchQuery" :placeholder="placeholder" maxlength="99" @focus="showOptions = true">
      <span
        v-if="!loading"
        :class="['dropdown-btn icomoon-chevron-down', {opened: showOptions}]"
        @click="showOptions = !showOptions"
      />
      <loading v-if="loading" class="loading-icon dropdown-btn"/>
      <div v-if="showOptions" class="options-outside" @click="showOptions = false"></div>
      <div v-if="showOptions" class="options" :style="`maxHeight: ${optionsHeight}px`">
        <div
          v-for="option in filteredOptions"
          :key="option.id"
          :class="['option-item', {'newOption' : String(option[itemValue]).includes('newOption')}]"
          @click.prevent="selectOption(option)"
        >
          <div>
            {{ option[itemText] }}
            <span
              v-if="option[itemDescription] && !String(option[itemValue]).includes('newOption')"
              class="option-description"
            >
              ({{option[itemDescription]}} {{itemDescriptionAppend}})
            </span>
          </div>
          <button
            v-if="String(option[itemValue]).includes('newOption')"
            @click.prevent
          >Add</button>
        </div>
        <div v-if="!filteredOptions.length" class="no-option">No options available</div>
      </div>
    </div>
    <span v-if="append" class="append">{{ append }}</span>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      required: true,
    },
    itemDescription: {
      type: String,
      default: '',
    },
    itemDescriptionAppend: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: null,
    },
    optionsHeight: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      searchQuery: '',
      showOptions: false,
      newOptionIndex: 0,
    }
  },
  computed: {
    filteredOptions() {
      if (!this.searchQuery) {
        return this.options
      }
      const selectedOption = this.options.find(option => option[this.itemText] === this.searchQuery);
      const filteredArray = this.options.filter(option => option[this.itemText].toLowerCase().includes(this.searchQuery.toLowerCase()));
      if (!selectedOption) {
        const newOption = {};
        newOption[this.itemText] = this.searchQuery;
        newOption[this.itemValue] = `newOption${this.newOptionIndex}`
        filteredArray.unshift(newOption)
      }
      return filteredArray;
    },
  },
  watch: {
    options() {
      if (this.value) {
        const selectedOption =  this.options.find(option => option[this.itemValue] === this.value);
        this.searchQuery = selectedOption[this.itemText];
      }
    },
  },
  methods: {
    selectOption(option) {
      this.searchQuery = option[this.itemText];
      console.log({option, search: this.searchQuery})
      if (String(option[this.itemValue]).includes('newOption')) {
        this.newOptionIndex++;
        this.$refs.input.focus();
        this.addItem(option[this.itemText])
        return;
      }
      this.$emit('select', option[this.itemValue]);
      this.showOptions = false;
    },
    addItem(item) {
      this.$emit('add', item);
      this.showOptions = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/imports.scss";

.s-select-wrapper input {
  background: white;
  position: relative;
  z-index: 10;
}

.dropdown-btn {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  z-index: 10;

  &.opened {
    transform: rotate(180deg);
  }
}

.options-outside {
  position: fixed;
  inset: 0;
}

.options {
  position: absolute;
  top: 60px;
  width: 100%;
  overflow-y: auto;
  background: $white;
  border: 1px solid $border-color-default;
  z-index: 20;
}
.no-option {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  color: $grey-extra-6;

}

.option-item {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: $grey-extra-1;

  &:hover {
    background: $background-light-hover;
  }

  &.newOption:hover {
    background: $background-light-hover;
  }

  button {
    padding: 4px 6px;
    border-radius: 4px;
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    cursor: pointer;
  }

  .option-description {
    color: $grey-extra-7;
  }
}

.loading-icon {
  width: 20px;
  height: 20px;
}
</style>
