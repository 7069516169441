<template>
  <div class="my-timers">
    <div class="panel-header">
      <div>
        <h1 class="is-1">Timers</h1>
      </div>
      <div>
        <h3 class="is-3">{{ today | formatDate }}</h3>
      </div>
    </div>

    <div class="panel-content">
      <div v-for="t in entries" :key="t.id" class="collapse-wrapper">
        <div
          slot="trigger"
          :class="{running: t.isRunning}"
          class="timer-wrapper"
          role="button"
          aria-controls="contentIdForA11y2">
          <div class="inner">
            <div>
              <h3 class="is-3">{{ t.project.name }} <span class="small">({{t.project.customer.name}})</span></h3>
              {{ t.task.name }} ({{ t.segment.name }})
            </div>
          </div>
          <div class="actions">
            <timer :task="t.task" hide-maximize></timer>
          </div>
        </div>
      </div>
      <div v-if="!entries.length">
        <s-loading :is-full-page='false' />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import entriesQuery from "~/graphql/queries/entries.graphql";
  import SLoading from '~/components/utils/SLoading.vue'

  export default {
    components: { SLoading },
    data () {
      return {
        entries: [],
      }
    },
    apollo: {
      entries: {
        query: entriesQuery,
        variables () {
          return {
            organisationId: this.$route.params.organisation,
            onlyMyEntries: true,
            finished: false,
          }
        }
      },
    },
    computed: {
      today () {
        return moment()
      },
      allTimer () {
        return this.$store.getters['timer/all']
      }
    },
    watch: {
      async allTimer() {
        await this.$apollo.queries.entries.refetch()
        if (this.entries.length === 0) {
          this.$emit('closePanel')
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  form {
    margin-top: rem(15px);
    padding-top: rem(15px);
  }

  .small {
    @include responsive-typography($font-size-small);
  }

  .arrow {
    display: flex;
    align-items: center;
  }

  .timer-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: $timer_orange_light;
    border-radius: $border-radius;
    padding: 10px 10px 10px 20px;
    border: $border-thin solid white;

    &.running {
      background-color: $timer_green_light;
    }

    .inner {
      display: grid;
      grid-template-columns: auto;
      align-items: center;

      > div {
        &:last-of-type {
          padding-left: 10px;
        }
      }
    }

    .icomoon-chevron-down {
      font-size: 0.4rem;
      color: $charcoal;

      &:hover {
        text-decoration: none;
        color: $slate;
      }
    }
  }

  .collapse-wrapper {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
</style>
