<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <s-notification-list />
    <form method="post" @submit.prevent="submit">
      <s-card-content>
        <validation-provider v-slot="{ errors }" name="customer" vid="customer" rules="required" slim>
          <s-field label="Client *" :has-error="errors[0]" :error-message="errors[0]">
            <s-select-with-add
              v-model="payload.customerId"
              placeholder="Select a client"
              item-text="name"
              item-value="id"
              :options="customers"
              :loading="addingCustomer"
              @add="addCustomer"
            />
          </s-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="name" vid="name" rules="required" slim>
          <s-field label="Project name *" :type="{ 'is-danger': errors[0] }" :message="errors[0]">
            <s-input v-model="payload.name" name="name"></s-input>
          </s-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="project_budget" vid="project_budget" rules="" slim>
          <s-field label="Project budget" :type="{ 'is-danger': errors[0] }" :message="errors[0]">
            <s-input
              v-model="payload.budget"
              placeholder="0"
              name="project_budget"
              type="number"
              inner-append="CHF"
            ></s-input>
          </s-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="description" vid="description" rules="" slim>
          <s-field is-optional label="Description" :type="{ 'is-danger': errors[0] }" :message="errors[0]">
            <s-input v-model="payload.description" is-optional type="textarea" name="description" />
          </s-field>
        </validation-provider>
      </s-card-content>

      <s-card-footer>
        <s-button @click="$emit('cancel')"> Cancel </s-button>
        <s-button v-if="project" has-i-trash is-danger @click="archiveProject"> Archive project </s-button>
        <s-button is-primary native-type="submit" :disabled="invalid"> Submit </s-button>
      </s-card-footer>
    </form>
  </validation-observer>
</template>

<script>
import customersQuery from '~/graphql/queries/customers.graphql'
import createProjectMutation from '~/graphql/mutations/createProject.graphql'
import updateProjectMutation from '~/graphql/mutations/updateProject.graphql'
import deleteProjectMutation from '~/graphql/mutations/deleteProject.graphql'
import createCustomerMutation from '~/graphql/mutations/createCustomer.graphql'

export default {
  props: {
    project: {
      type: Object,
      required: false,
      default: null,
    },
    customer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        name: '',
        budget: null,
        description: '',
        customerId: null,
      },
      addingCustomer: false,
    }
  },
  apollo: {
    customers: {
      query: customersQuery,
      variables() {
        return {
          organisationId: this.$route.params.organisation,
        }
      },
    },
  },
  mounted() {
    if (this.project) {
      this.payload = {
        ...this.project,
      }
    } else {
      if (this.customer) {
        this.payload.customerId = this.customer.id
      }
    }
  },
  methods: {
    async addCustomer(customer) {
      this.addingCustomer = true
      const payload = {
        name: customer,
        short: customer.replaceAll(' ', '').substring(0, 4),
        internal: false,
      }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: createCustomerMutation,
          variables: {
            ...payload,
            organisationId: this.$route.params.organisation,
          },
        })
        await this.$apollo.queries.customers.refetch()
        this.payload.customerId = data.createCustomer.id
        this.$notify('Client has been added successfully! 🎉', 'is-success', true)
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
        }
      }
      this.addingCustomer = false
    },
    async archiveProject() {
      try {
        await this.$apollo.mutate({
          mutation: deleteProjectMutation,
          variables: {
            id: this.project.id,
          },
        })
        this.$emit('archived')
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
        }
      }
    },
    async submit() {
      this.payload.budget = +this.payload.budget
      if (this.project) {
        try {
          const response = await this.$apollo.mutate({
            mutation: updateProjectMutation,
            variables: this.payload,
          })
          this.$emit('save', response)
        } catch (e) {
          if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
            this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
          }
        }
      } else {
        try {
          const response = await this.$apollo.mutate({
            mutation: createProjectMutation,
            variables: this.payload,
          })
          this.$emit('save', response)
        } catch (e) {
          console.log('error', e)
          if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
            this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/imports.scss';
::v-deep {
  .card-content {
    padding: 32px 0 64px 0 !important;
  }
  .s-field {
    label {
      color: $grey-extra-4 !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 109.5% !important;
    }
    input,
    textarea {
      color: $grey-extra-1 !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 90.5% !important;
      letter-spacing: -0.5px !important;
    }
  }
}
</style>
