import {ApolloLink} from 'apollo-link'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from "apollo-link-error";
import errorToast from "~/graphql/errorToast";
import Cookies from 'js-cookie'
import $notify  from '~/plugins/helpers'

const openToast = (message) => {
  $notify(message, 'is-danger', true)
}


export default (ctx) => {
  const httpLink = new HttpLink({
    uri: `${ctx.$config.baseUrl}/graphql`,
  })

  const middlewareLink = new ApolloLink((operation, forward) => {
    let token;
    try {
      token = ctx.app.$apolloHelpers.getToken() || null;
    } catch (e) {
      console.log('###Error: apollo getToken', e)
      return;
    }
    operation.setContext({
      headers: {
        authorization: token ? 'jwt ' + token  : null,
      },
      start: new Date()
    })
    return forward(operation).map((data) => {
      // Called after server responds
      const time = new Date() - operation.getContext().start;
      if (time > 4000) {
        openToast( 'Slow internet connection')
      }
      return data;
    });
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.log('###error link =>', {graphQLErrors, networkError});
    if (graphQLErrors?.[0]?.type === "NotAuthenticated") {
      Cookies.remove('sybill-apollo-token');
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError.message}`);
      if (networkError.message.includes('token')) {
        console.log('### token had some issues, logging out', networkError.message)
        Cookies.remove('sybill-apollo-token');
        ctx.app.$apolloHelpers.onLogout();
      }
    }
    errorToast({graphQLErrors, networkError})
  });

  const link = ApolloLink.from([errorLink, middlewareLink, httpLink])
  return {
    link,
    cache: new InMemoryCache(),
    defaultHttpLink: false,
  }
}
