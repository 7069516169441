<template>
  <div
    class="s-input"
    :class="{
      'is-search': isSearch,
      'has-append': append,
      'has-inner-append': innerAppend,
      'has-reveal-password': revealPassword,
      'has-icon': icon,
    }"
  >
    <input
      v-if="inputType !== 'textarea'"
      :type="inputType"
      :placeholder="placeholder"
      :required="!isOptional"
      :value="computedValue"
      :disabled="isDisabled"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <textarea
      v-else
      :type="inputType"
      :placeholder="placeholder"
      :required="!isOptional"
      :value="computedValue"
      :disabled="isDisabled"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <span v-if="innerAppend" class="inner-append">{{ innerAppend }}</span>
    <span v-if="append" class="append">{{ append }}</span>
    <span v-if="revealPassword" class="reveal-password" @click="toggleShowPassword">
      {{ showPassword ? 'Hide' : 'Show' }}
    </span>
    <span v-if="icon" class="input-icon">
      <i :class="`icomoon-${icon}`"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: null,
    },
    innerAppend: {
      type: String,
      default: null,
    },
    revealPassword: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: this.value,
      isFocused: false,
      inputType: '',
      showPassword: false,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    },
  },
  mounted() {
    this.inputType = this.type
  },
  methods: {
    onInput(event) {
      const value = event.target.value
      this.updateValue(value)
    },
    onBlur($event) {
      this.isFocused = false
      this.$emit('blur', $event)
    },
    onFocus($event) {
      this.isFocused = true
      this.$emit('focus', $event)
    },
    updateValue(value) {
      this.computedValue = value
    },
    toggleShowPassword() {
      if (!this.showPassword) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }

      this.showPassword = !this.showPassword
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/imports.scss';

.inner-append {
  position: absolute;
  right: 16px;
  color: $grey-extra-7;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
}
.s-input {
  position: relative;
  align-items: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
