import Vue from 'vue'
import { EventBus } from '~/components/utils/event-bus'

export default Vue.prototype.$notify = (message, type, isAutoClose, subtitle) => {
  const lastMessage = localStorage?.getItem('lastMessage')
  setTimeout(() => {
    localStorage.setItem('lastMessage', '')
  }, 1000)
  if (message === lastMessage) return
  localStorage?.setItem('lastMessage', message)
  const obj = { message, type, autoClose: isAutoClose, subtitle }
  EventBus.$emit('obj', obj)
}
