<template>
  <div class="modal-wrapper" :style="width ? `width: ${width}px` : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: false,
      default: '',
    }
  }
}
</script>

<style lang="scss">
@import "~assets/scss/imports.scss";

.modal-wrapper {
  padding: 32px;
  background: white;
  box-shadow: none;
  margin: 0 auto;
}

.modal-card-head {
  background-color: white;
  border: none;
}

.modal-card-foot {
  background-color: white;
  border-top: none;
  padding: 0;
  justify-content: right;
}
</style>
