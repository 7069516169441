<template>
  <div class="main">
    <div class="workspace-header">
      <logo class='logo'/>
      <b-dropdown ref="dropdown" aria-role="list" position='is-bottom-left'>
        <template #trigger="">
          <div class="user-dropdown">
            <img
              v-if="pictureUrl"
              :src="pictureUrl"
              alt="Profile Picture"
            >
            <AvatarIcon
              v-else
              class="avatar"
            />
            <span>{{ fullName }}</span>
            <span class="s-chevron-up-down"/>
          </div>
        </template>
        <b-dropdown-item class="logout" @click="logout">Log out</b-dropdown-item>
      </b-dropdown>
    </div>
    <nuxt/>
  </div>
</template>

<script>
  import Logo from '~/components/Logo.vue'
  import profileQuery from '~/graphql/queries/profile.graphql'
  import Cookies from 'js-cookie'

  export default {
    components: { Logo },
    apollo: {
      profile: {
        query: profileQuery,
      },
    },
    computed: {
      userProfile() {
        if (this.profile) {
          return this.profile.userProfile
        }
        return {}
      },
      pictureUrl() {
        if (this.userProfile?.picture) {
          return `https://${this.$config.imageServer}/?image=${this.userProfile?.picture}&width=200&height=200&crop=1,1`
        }
        return ''
      },
      fullName() {
        return this.profile?.fullName || "-"
      },
    },
    mounted() {
      window.addEventListener("offline", () => {
        this.$notify('You are offline!', 'is-danger', true);
      });

      window.addEventListener("online", () => {
        this.$notify('You are back online! 🎉', 'is-success', true);
      });

      if (window.navigator.connection?.effectiveType.endsWith("2g")) {
        this.$notify('Slow internet connection', 'is-warning', true);
      }
    },
    methods: {
      async logout() {
        await this.$store.dispatch('timer/saveAllTimers')
        // passing `true` as second to skip reset store (https://github.com/nuxt-modules/apollo/blob/d1d3caf17595d7ddab6cad0425701f10af5d384a/src/runtime/composables.ts)
        await this.$apolloHelpers.onLogout(this.$apolloProvider.defaultClient, true);
        Cookies.remove('sybill-apollo-token');
        await this.$router.push({name: "login", query: {isLogout: "1"}})
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";
  .main {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
  }

  .workspace-header {
    display: flex;
    justify-content: space-between;
    padding: rem(24px) rem(64px) rem(12px) rem(64px);
  }

  .user-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(12px);
    background: $background-light;
    padding-right: rem(24px);

    &:hover {
      cursor: pointer;
      background: $background-light-hover;
    }
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
  }

  ::v-deep .dropdown-content {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
  }
  .logout {
    padding-bottom: rem(13px);
    padding-top: rem(13px);
    display: flex;

    &:hover {
      background-color: $background-light-hover
    }
  }

</style>
