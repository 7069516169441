<template>
  <s-tag v-if="status === 'PENDING'" class="pending">Pending</s-tag>
  <s-tag v-else-if="status === 'IN_PROGRESS'" is-progress>In progress</s-tag>
  <s-tag v-else-if="status === 'DONE'" is-done>Done</s-tag>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    }
  }
}
</script>
