<template>
  <div class="main">
    <header>
      <logo
        class="logo"
        @click.native="goToIndexPage"
      />
    </header>
    <nuxt class="email-content"/>
    <footer>
      <div class="made-with">
        <p>
          Made with
          <img src="~/assets/heart.png" class="heart" width="24" height="24"/>
          by Pyango GmbH
        </p>
        <span>&#169; {{ year }} Pyango GmbH</span>
      </div>
      <a href="https://www.linkedin.com/company/pyango-gmbh" target="_blank">
        <img src="~/assets/linkedin.png" alt="" width="24" height="24">
      </a>
    </footer>
  </div>
</template>

<script>
  import Logo from '~/components/Logo.vue'

  export default {
    components: { Logo },
    data() {
      return {
        year: new Date().getFullYear()
      }
    },
    methods: {
      goToIndexPage() {
        this.$router.push('/')
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "~assets/scss/imports.scss";

  .main {
    height: 100vh;
    width: 600px;
    max-width: 100vw;
    overflow-y: auto;
    background-image: url("../assets/stars.png");
    background-repeat: no-repeat;
    background-position: bottom 148px right 32px;
    background-size: 100px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .main header {
    width: 536px;
    margin: 0 auto;
    padding: 28px 0;
    border-bottom: 1px solid $border-color-default;
  }

  .logo {
    cursor: pointer;
  }

  .main .email-content {
    width: 100%;
    padding: 0 32px;
  }

  .main footer {
    width: 536px;
    max-width: 100vw;
    padding: 25px 0 16px 0;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-color-default;
  }

  .made-with {
    p {
      color: $primary;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 9px;
    };

    span {
      font-weight: 500;
      font-size: 16px;
      color: $grey-extra-6;
    }
  }

  .heart {
    position: relative;
    top: 6px;
    margin: 0 2px;
  }
</style>
