export default function ({ isHMR, req, redirect, route }) {
  if (isHMR) return;

  const userAgent = process.client ? navigator.userAgent : req.headers['user-agent'];

  if (/Mobi|Android/i.test(userAgent)) {
    if (route.path !== '/mobile-not-ready') {
      return redirect('/mobile-not-ready');
    }
  }
}
