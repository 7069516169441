t<template>
  <div
    class="s-number"
    :class="{
      'is-xl':isXl,
      'is-large':isLarge,
      'is-medium':isMedium,
      'is-small':isSmall,
      'is-green':isGreen,
      'is-orange':isOrange,
      'is-red':isRed,
    }">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
@import "~assets/scss/imports";

.s-number {
  background-color: transparent;
  border-radius: 0;
  height: auto;
  margin-right: initial;
  min-width: auto;
  padding: initial;
  align-items: initial;
  display: initial;
  justify-content: initial;
  text-align: initial;
  vertical-align: initial;
  letter-spacing: -0.035rem !important;

  span {
    margin-left: 1px;

    &.text {
      margin-left: 5px;
    }
  }
}
</style>
<script>
export default {
  props: {
    isXl: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    isMedium: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    isOrange: {
      type: Boolean,
      default: false,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
