<template>
  <div
    class="s-table-cell"
    :class="{
        'is-pull-right':isPullRight,
      }">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-table-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .is-pull-right {
    justify-content: flex-end;
  }
</style>
<script>
  export default {
    props: {
      isPullRight: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
