import Vue from 'vue'
import * as Sentry from '@sentry/vue'

export default (ctx) => {
  Sentry.init({
    Vue,
    dsn: ctx.$config.sentryDsn,
    environment: ctx.$config.environment,
    release: ctx.$config.release,
    debug: true,
    logErrors: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,  // Change when we have more users
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
        }), 
        new Sentry.BrowserTracing()],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({eventId: event.event_id})
      }
      return event
    },
  })
}
