<template>
  <div>
    <button
      :class="classObject"
      @click="toggle">
      <span v-if="!isOpen">{{ titleIn }}</span>
      <span v-if="isOpen">{{ titleOut }}</span>
    </button> <!-- v-if="isOpen" -->
    <div ref="myText" :style="[isOpen ? { height : computedHeight } : {}]" class="collapse-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    titleIn: {
      type: String,
      default: 'Show more'
    },
    titleOut: {
      type: String,
      default: 'Show less'
    },
    showArrows: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      newValue: this.title,
      isOpen: this.open,
      computedHeight: 0
    }
  },
  computed: {
    classObject: function () {
      return {
        'btn-link': this.showArrows,
        'link-chevron-up': this.isOpen && this.showArrows,
        'link-chevron-down': !this.isOpen && this.showArrows,
        's-btn': !this.showArrows,
      }
    },
  },
  mounted: function(){
      this.initHeight()
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    initHeight: function(){

      this.$refs['myText'].style.height = 'auto';
      this.$refs['myText'].style.position = 'absolute';
      this.$refs['myText'].style.visibility = 'hidden';
      this.$refs['myText'].style.display = 'block';

      const height = getComputedStyle(this.$refs['myText']).height;      
      this.computedHeight = height;  

      this.$refs['myText'].style.position = null;
      this.$refs['myText'].style.visibility = null;
      this.$refs['myText'].style.display = null;
      this.$refs['myText'].style.height = 0;

    }
  },
}
</script>
<style lang="scss" scoped>
@import "~assets/scss/imports";

.collapse-content {
  margin-top: 10px;

  height: 0;
  overflow: hidden;
  transition: 0.3s;
}
</style>
