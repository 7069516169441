import jwt_decode from "jwt-decode";
import moment from 'moment';
import Cookies from 'js-cookie';

export default async function ({ app, redirect, route }) {
  let token;
  try {
    token = app.$apolloHelpers.getToken() || null;
  } catch (e) {
    console.log('###Error: apollo getToken', e)
    return;
  }
  const hasToken = !!token;

  const { exp } = token ? jwt_decode(token) : 0;
  const diff = token ? moment().diff(exp * 1000) : 0;
  // If the user is not authenticated or the token is expired
  if (!hasToken || diff >= 0) {
    console.log('redirect to login page since the user is not authenticated or the token is expired');
    await Cookies.remove('jwt', {path: ''});
    return redirect('/login')
  } else {
    if (route.params.organisation) {
      await app.store.dispatch('timer/clearAll')
    }
    await app.store.dispatch('timer/getMyEntries', route.params.organisation)
  }
}
