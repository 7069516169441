<template>
  <div>
    <div v-if="!isPaused" class="timer-dot-wrapper">
      <span class="bullet green"></span>
      <p>Timer is running</p>
    </div>
    <div v-else class="timer-dot-wrapper">
      <span class="bullet orange"></span>
      <p>Timer is paused</p>
    </div>
  </div>


  <!--<s-button class="is-reset" :class="[color, {'is-running': isRunning}]"
            @click="$emit('click', $event)"></s-button>-->
</template>

<script>
  export default {
    props: {
      isRunning: {
        type: Boolean,
        required: false,
      },
      isPaused: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .timer-dot-wrapper {
    display: flex;
    align-items: center;
  }

  .bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;

    &.orange {
      background-color: $timer-orange;
    }

    &.green {
      background-color: $timer-green;
    }
  }

</style>
