var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-field",class:{
    'has-error':_vm.hasError,
    'is-optional':_vm.isOptional,
    'is-small-v':_vm.isSmallV,
    'is-small-h':_vm.isSmallH,
    'is-medium-h':_vm.isMediumH,
    'is-large-h':_vm.isLargeH,
    'is-horizontal':_vm.isHorizontal,
    },attrs:{"width":_vm.width}},[(_vm.label)?_c('label',[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),(_vm.required)?_c('span',{staticClass:"star"},[_vm._v("\n      *\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isFormGroup)?_c('div',{staticClass:"form-group-wrapper"},[_vm._t("default")],2):_vm._t("default"),_vm._v(" "),(_vm.errorMessage)?_c('small',{staticClass:"is-danger"},[_vm._v("\n    "+_vm._s(_vm.errorMessage)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.newMessage && !_vm.isHorizontal)?_c('p',{staticClass:"help",class:_vm.newType},[(_vm.$slots.message)?_vm._t("message"):[_vm._l((_vm.formattedMessage),function(mess,i){return [_vm._v("\n            "+_vm._s(mess)+"\n            "),((i + 1) < _vm.formattedMessage.length)?_c('br',{key:i}):_vm._e()]})]],2):_vm._e(),_vm._v(" "),(_vm.help)?_c('small',{staticClass:"text-muted"},[_vm._v("\n    "+_vm._s(_vm.help)+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }