<template>
  <transition :name="animation">
    <div
      v-if="isActive"
      class="loading-overlay is-active"
      :class="{ 'is-full-page': displayInFullPage }">
      <div class="loading-background" @click="cancel"/>
      <slot>
        <div class="loading-icon"/>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'update:active'
  },
  props: {
    active: {
      type: Boolean,
      default: true
    },
    isFullPage: {
      type: Boolean,
      default: true
    },
    animation: {
      type: String,
      default: 'fade'
    },
    canCancel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false,
      displayInFullPage: this.isFullPage
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    isFullPage(value) {
      this.displayInFullPage = value
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress)
    }
  },
  methods: {
    cancel() {
      if (!this.canCancel || !this.isActive) return
      this.close()
    },
    close() {
      this.$emit('close')
      this.$emit('update:active', false)
    },
    keyPress({key}) {
      if (key === 'Escape' || key === 'Esc') this.cancel()
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";
  .loading-overlay .loading-background{
    background-color: $background-light !important;
    opacity: 0.5;
  }
</style>
