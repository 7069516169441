import 'clipboard'

import Vue from 'vue'
import Prism from 'vue-prism-component'
import 'prismjs'

import 'prismjs/themes/prism.css'

import 'prismjs/components/prism-scss.min'

import 'prismjs/components/prism-scss'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'

import 'prismjs/plugins/autolinker/prism-autolinker.min'
import 'prismjs/plugins/autolinker/prism-autolinker.css'

Vue.component('prism', Prism);
