<script>
import profileQuery from '~/graphql/queries/profile.graphql'
import organisationsQuery from '~/graphql/queries/organisations.graphql'
import updateUserProfileMutation from '~/graphql/mutations/updateUserProfile.graphql'
import AvatarIcon from '~/components/utils/AvatarIcon.vue'
import Cookies from 'js-cookie'
import Logo from '~/components/Logo.vue'
import TrackTimeModal from '~/components/modals/TrackTimeModal.vue'

export default {
  components: {
    AvatarIcon,
    Logo,
  },
  apollo: {
    profile: {
      query: profileQuery,
    },
    organisations: {
      query: organisationsQuery,
    },
  },
  computed: {
    allTimers() {
      return this.$store.getters['timer/all']
    },
    runningTimer() {
      return this.allTimers.find((e) => !e.finished && e.isRunning)
    },
    userProfile() {
      if (this.profile) {
        return this.profile.userProfile
      }
      return {}
    },
    fullName() {
      return this.profile?.fullName || '-'
    },
    currentOrganisation() {
      return this.organisations?.find((organisation) => organisation.id === this.currentOrganisationId)
    },
    currentOrganisationId() {
      return parseInt(this.$route.params.organisation)
    },
    sidebarItems() {
      return [
        {
          title: 'Dashboard',
          routeName: 'organisation',
          to: `/${this.currentOrganisationId}`,
          icon: 'icomoon-grid',
        },
        {
          title: 'My Timesheets',
          routeName: 'organisation-my-time',
          to: `/${this.currentOrganisationId}/my-time`,
          icon: 'icomoon-clock',
        },
        {
          title: 'Projects',
          routeName: 'organisation-projects',
          to: `/${this.currentOrganisationId}/projects`,
          icon: 'icomoon-box',
        },
        {
          title: 'Clients',
          routeName: 'organisation-customers',
          to: `/${this.currentOrganisationId}/customers`,
          icon: 'icomoon-users',
        },
        {
          title: 'Team',
          routeName: 'organisation-team-members',
          to: `/${this.currentOrganisationId}/team/members`,
          icon: 'icomoon-user-plus',
        },
        {
          title: 'Settings',
          routeName: 'organisation-settings',
          to: `/${this.$route.params.organisation}/settings`,
          icon: 'icomoon-settings-single',
        },
      ]
    },
    pictureUrl() {
      if (this.userProfile?.picture) {
        return `https://${this.$config.imageServer}/?image=${this.userProfile?.picture}&width=200&height=200&crop=1,1`
      }
      return ''
    },
    organisationLogoUrl() {
      if (this.currentOrganisation?.logo) {
        return `https://${this.$config.imageServer}/?image=${this.currentOrganisation?.logo}&width=200&height=200&crop=1,1`
      }
      return ''
    },
  },
  watch: {
    organisations() {
      if (this.organisations && !this.currentOrganisation) {
        console.log('redirected to homepage from NavigationSide')
        this.$router.push('/')
      }
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('timer/saveAllTimers')
      // passing `true` as second to skip reset store (https://github.com/nuxt-modules/apollo/blob/d1d3caf17595d7ddab6cad0425701f10af5d384a/src/runtime/composables.ts)
      await this.$apolloHelpers.onLogout(this.$apolloProvider.defaultClient, true)
      Cookies.remove('sybill-apollo-token')
      await this.$router.push({ name: 'login', query: { isLogout: '1' } })
    },
    async updateProfile(organisation) {
      await this.$apollo.mutate({
        mutation: updateUserProfileMutation,
        variables: { currentOrganisation: organisation.id },
      })
      this.$apollo.queries.profile.refetch()
      this.$router.push(`/${organisation.id}`)
    },
    isNavItemActive(routeName) {
      return (
        (routeName !== 'organisation' && this.$route.name.startsWith(routeName)) ||
        (routeName === 'organisation' && this.$route.name === routeName)
      )
    },
    goToMePage() {
      this.$refs.dropdown.isActive = false
      this.$router.push({ name: 'organisation-me', params: { organisation: `${this.currentOrganisationId}` } })
    },
    async goToWorkspaces() {
      await this.$router.push('/')
    },
    openTrackTimeModal() {
      if (!this.currentOrganisation?.canAccess) return
      this.$buefy.modal.open({
        parent: this,
        canCancel: ['escape', 'outside'],
        component: TrackTimeModal,
      })
    },
  },
}
</script>

<template>
  <nav class="sidenav">
    <div>
      <nuxt-link v-if="currentOrganisationId" class="logo" to="/">
        <logo style="width: 75px" />
      </nuxt-link>
      <hr class="soft-border" />
      <div class="profile-section">
        <b-dropdown ref="dropdown" aria-role="list">
          <template #trigger="">
            <div class="workspace">
              <OrganisationProfileLogo :organisation-logo="organisationLogoUrl" :user-profile-picture="pictureUrl" />
              <b-tooltip :label="currentOrganisation?.name || 'More...'" position="is-right">
                <p ref="organisation-name" class="organisation-name">{{ currentOrganisation?.name }}</p>
              </b-tooltip>
              <span class="s-chevron-up-down" />
            </div>
          </template>
          <div class="profile-dropdown-header" @click="goToMePage">
            <img v-if="pictureUrl" :src="pictureUrl" alt="Profile Picture" />
            <AvatarIcon v-else class="inline" />
            <div class="inline user-info">
              <div>
                {{ fullName }}
              </div>
              <b-tooltip :label="userProfile.email" position="is-bottom">
                <div class="email">
                  {{ userProfile.email }}
                </div>
              </b-tooltip>
            </div>
          </div>
          <hr class="soft-border" />
          <div class="workspaces">
            <div class="workspaces-title">Workspaces</div>
            <b-dropdown-item
              v-for="o in organisations"
              :key="o.id"
              class="workspace-item"
              style="justify-content: space-between"
              aria-role="listitem"
              @click="updateProfile(o)"
            >
              <span>{{ o.name }}</span>
              <span v-if="o.id === currentOrganisationId" class="s-check" />
            </b-dropdown-item>
            <b-dropdown-item class="workspace-item" @click="goToWorkspaces">
              <span>Show workspaces</span>
              <span class="s-arrow-right"></span>
            </b-dropdown-item>
          </div>
          <hr class="soft-border" />
          <b-dropdown-item class="logout" @click="logout"> Log out </b-dropdown-item>
        </b-dropdown>
      </div>
      <hr class="soft-border" />
    </div>
    <div class="mainnav" role="navigation">
      <div
        v-for="item in sidebarItems"
        :key="item.title"
        :class="['nav-item', { active: isNavItemActive(item.routeName) }]"
      >
        <nuxt-link :to="item.to">
          <i :class="item.icon" />
          {{ item.title }}
        </nuxt-link>
      </div>
      <div class="nav-item track-time-wrapper" :class="!!runningTimer ? 'active' : 'not-active'">
        <div
          class="track-time"
          :class="{ 'is-disabled': !currentOrganisation || !currentOrganisation.canAccess }"
          @click="openTrackTimeModal"
        >
          <i class="icomoon-plus-square" />

          {{ !!runningTimer ? 'Track a new task' : 'Track time' }}
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@import '~assets/scss/imports';

$nav-hover-bg-color: rgba(255, 255, 255, 0.05);
$nav-border: 0.5px solid rgba(255, 255, 255, 0.1);
$nav-active-bg-color: $emerald;

.sidenav {
  display: flex;
  flex-flow: column;
  background: $background-light;
  color: black;
  position: relative;
  z-index: 15;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $border-default;

  @media print {
    display: none;
  }

  .logo {
    display: grid;
    padding: 20px 0 20px 32px;
  }

  .mainnav {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin-bottom: auto;

    .nav-item {
      position: relative;
      width: 100%;
      border-top: $nav-border;
      &:hover {
        background-color: $background-light-hover;
      }
    }

    .nav-item.active {
      .nuxt-link-active {
        color: $primary;
      }
    }
    a,
    .track-time {
      cursor: pointer;
      padding: 12px 0;
      color: $grey-default;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $nav-hover-bg-color;
        text-decoration: none;
      }

      &.is-disabled {
        cursor: not-allowed;
      }

      [class^='icomoon-'] {
        font-size: 1rem;
        margin-right: 8px;
        padding-left: 32px;
      }
    }

    .nav-item.track-time-wrapper {
      border-radius: 0 24px 24px 0;
      margin-right: 24px;
      position: absolute;
      bottom: 120px;
      .track-time {
        padding: 24px 0;
      }
      &.not-active {
        background-color: $primary;
        .track-time {
          color: $white;
        }
      }
      &.active {
        border: 2px solid $primary;
        .track-time {
          color: $primary;
        }
      }
    }
  }

  .subnav {
    display: flex;
    flex-flow: column;
    width: 100%;

    > a {
      padding-left: rem(40px);
      border-bottom: none;
      border-top: none;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.active {
  &::after {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    width: 4px;
    height: 24px;
    background-color: $primary;
    border-radius: 0 2px 2px 0;
  }
}

.profile-section {
  box-sizing: border-box;
  gap: rem(8px);

  padding: rem(12px) 0 rem(12px) rem(32px);

  height: rem(72px);

  .workspace {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(16px);
    padding: rem(12px) rem(24px) rem(12px) 0;

    height: rem(48px);

    background: $background-light;

    &:hover {
      cursor: pointer;
      background: $background-light-hover;
    }
  }

  .profile-dropdown-header {
    width: rem(240px);
    padding-left: rem(17px);
    padding-top: rem(14px);
    margin-top: rem(8px);
    padding-bottom: rem(6px);
    margin-bottom: rem(12px);
    align-items: flex-start;
    display: flex;

    &:hover {
      cursor: pointer;
      background-color: $background-light-hover;
    }
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }

  .inline {
    display: inline-block;
  }

  .user-info {
    word-break: break-word;
    margin-left: rem(10px);
  }

  .email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: rem(160px);
    color: $grey-2;
  }

  .workspaces {
    background-color: $background-dark;

    .workspaces-title {
      padding-left: rem(16px);
      padding-top: rem(8px);
      padding-bottom: rem(12px);
      color: $grey-1;
    }

    .workspace-item {
      padding-bottom: rem(10px);
      padding-top: rem(10px);
      display: flex;

      &:hover {
        background-color: $background-neutral-hover;
      }
    }
  }

  .organisation-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
  }
}

.logout {
  padding-bottom: rem(10px);
  padding-top: rem(10px);

  &:hover {
    background-color: $background-light-hover;
  }
}

.soft-border {
  @include border-soft;
}

::v-deep .dropdown-content {
  padding-bottom: 0;
  margin-left: rem(35px);
}

.s-check {
  color: $alert-success;
}
</style>
