import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format'
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueTruncate from 'vue-truncate-filter'

momentDurationFormatSetup(moment)

Vue.use(VueTruncate)
Vue.use(VueResource)

Vue.filter('formatDate', function (value, newFormat) {
  if (!value) return '-'
  value = value.toString()
  if(newFormat) {
    return moment(value).format(newFormat)
  } else {
    return moment(value).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateShort', function (value) {
  if (!value) return '-'
  value = value.toString()
  return moment(value).format('DD.MM.YYYY')
})

Vue.filter('formatTime', function (value) {
  if (!value) return ''
  value = value.toString()
  return moment(value).format('HH:mm:ss')
})

Vue.filter('formatDateTime', function (value) {
  if (!value) return ''
  value = value.toString()
  return moment(value).format('DD.MM.YYYY HH:mm:ss')
})

Vue.filter('formatDuration', function (value, newFormat) {
  if (value == null) return ''
  const m = moment.duration(value, 'seconds')
  if (m.asMilliseconds()) {
    const format = newFormat ? newFormat : 'H [h] m [m]'
    return m.format(format, { minValue: 1, trim: 'both', trunc: true, })
  }
  return m.format('H [h]', { trim: true })
})

Vue.filter('formatStatus', function (value) {
  const statusMap = {
    IN_PROGRESS: 'In progress',
    DONE: 'Done',
  }
  if (value == null || !Object.keys(statusMap).includes(value)) return ''
  return statusMap[value]
})

Vue.filter('financial', function (value) {
  return Number.parseFloat(value).toFixed(2)
})

Vue.filter('formatPrice', function (price) {
    const formatter = new Intl.NumberFormat('de-CH', {
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(price)
})

Vue.filter('formatPriceWithDecimals', function (price) {
    const formatter = new Intl.NumberFormat('de-CH', {
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(price)
})

Vue.directive('init', {
  bind: function (el, binding, vnode) {
    vnode.context[binding.arg] = binding.value
  }
})

Vue.filter('formatSeconds', function(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(Math.floor((seconds % 3600) / 60));
  const hoursString = `${hours < 10 ? '0' + hours : hours}`;
  const minutesString = `${minutes < 10 ? '0' + minutes : minutes}`;
  return `${hoursString}:${minutesString} h`
})
