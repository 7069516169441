<template>
  <div class="s-select" :class="{'has-append':append}">
    <span v-if="prepend" class="prepend">{{ prepend }}</span>
    <div class="s-select-wrapper">
      <select
        ref="select"
        v-model="computedValue"
        :class="spanClasses"
        v-bind="$attrs"
        :disabled="isDisabled">
        <template v-if="placeholder">
          <option v-if="computedValue === null"
                  disabled
                  hidden
                  selected
                  :value="null">
            {{ placeholder }}
          </option>
        </template>
        <slot/>
      </select>
    </div>
    <span v-if="append" class="append">{{ append }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array, Function],
      default: null
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    }
  },
  data () {
    return {
      selected: this.value,
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.selected
      },
      set (value) {
        this.selected = value;
        this.$emit('input', value)
      }
    },
    spanClasses () {
      return [this.size, {
        'is-empty': this.selected === null,
        'has-prepend': this.prepend,
      }]
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/imports.scss";
.s-select {
  position: relative;
}

.has-prepend {
  padding-left: 32px;
  position: relative;
}

.prepend {
  position: absolute;
  left: 16px;
  top: 16px;
  color: $grey-extra-7;
  font-size: 18px;
}

</style>
