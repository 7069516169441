// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/stars.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes timer-pulse-6cd9606c{0%{box-shadow:0 0 0 0 rgba(122,204,51,.6)}70%{box-shadow:0 0 0 10px rgba(204,169,44,0)}to{box-shadow:0 0 0 0 rgba(204,169,44,0)}}.main[data-v-6cd9606c]{align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:bottom 148px right 32px;background-repeat:no-repeat;background-size:100px;display:flex;flex-direction:column;gap:12px;height:100vh;justify-content:space-between;margin:0 auto;max-width:100vw;overflow-y:auto;width:600px}.main header[data-v-6cd9606c]{border-bottom:1px solid #d3d8d9;margin:0 auto;padding:28px 0;width:536px}.logo[data-v-6cd9606c]{cursor:pointer}.main .email-content[data-v-6cd9606c]{padding:0 32px;width:100%}.main footer[data-v-6cd9606c]{align-items:center;background:transparent;border-top:1px solid #d3d8d9;display:flex;justify-content:space-between;max-width:100vw;padding:25px 0 16px;width:536px}.made-with p[data-v-6cd9606c]{color:#035c76;margin-bottom:9px}.made-with p[data-v-6cd9606c],.made-with span[data-v-6cd9606c]{font-size:16px;font-weight:500}.made-with span[data-v-6cd9606c]{color:#878787}.heart[data-v-6cd9606c]{margin:0 2px;position:relative;top:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
