<template>
  <fa v-if="value" icon="check" class="has-text-success"></fa>
  <fa v-else icon="times" class="has-text-danger"></fa>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  }
}
</script>
