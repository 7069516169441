var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-number",class:{
    'is-xl':_vm.isXl,
    'is-large':_vm.isLarge,
    'is-medium':_vm.isMedium,
    'is-small':_vm.isSmall,
    'is-green':_vm.isGreen,
    'is-orange':_vm.isOrange,
    'is-red':_vm.isRed,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }