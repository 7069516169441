<template>
  <div class="circular-progress-bar">
    <svg :width="size" :height="size">
      <circle
        :cx="radius"
        :cy="radius"
        :r="radius - strokeWidth / 2"
        :stroke-width="strokeWidth"
        :stroke="emptyColor"
        fill="none"
      />

      <circle
        :cx="radius"
        :cy="radius"
        :r="radius - strokeWidth / 2"
        :stroke-width="strokeWidth"
        :stroke="progressColor"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        fill="none"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SCircularProgressBar',
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    size: {
      type: Number,
      default: 100,
    },
    strokeWidth: {
      type: Number,
      default: 10,
    },
    emptyColor: {
      type: String,
      default: '#CCCCCC', 
    },
    progressColor: {
      type: String,
      default: '#3498db', 
    },
  },
  computed: {
    radius() {
      return this.size / 2;
    },
    circumference() {
      return Math.PI * (this.radius - this.strokeWidth / 2) * 2;
    },
    dashOffset() {
      return this.circumference * (1 - this.normalizedValue);
    },
    normalizedValue() {
      return Math.min(Math.max(this.value / 100, 0), 1);
    },
  },
};
</script>

<style scoped>
.circular-progress-bar {
  display: flex;
}
</style>
