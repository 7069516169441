import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required, min, email, max_value, min_value } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('min', min)
extend('max_value', max_value)
extend('min_value', min_value)
extend('email', {
  ...email,
  message: 'Please enter a valid email address'
})

extend('is-positive-duration', {
  validate: value => {
    let regex = new RegExp("^([0-9][0-9]):([0-5][0-9])$")
    return value && regex.test(value)
  },
  castValue: value => {
    if (value == null) return ''
    const m = moment.duration(value, 'seconds')
    return moment.utc(m.as('milliseconds')).format("HH:mm")
  },
  message: 'The format must be "HH:mm',
})
extend('is-positive-number', {
  validate: value => {
    if (value.length === 1) {
      value = `0${value}`
    }
    let regex = new RegExp("^[0-9].")
    return value && regex.test(value)
  },
  message: 'Must be a positive value',
})
extend('is-time', {
  validate: value => {
    let regex = new RegExp("^([01]\\d|2[0-3]):?([0-5]\\d)$")
    return value && regex.test(value)
  },
  message: 'The format must be HH:mm',
})
extend('is-date', {
  validate: value => {
    return value && Object.prototype.toString.call(value) === '[object Date]'
  },
  message: 'The format must be date object',
})
extend('decimal-2', {
  validate: value => {
    let regex = new RegExp("[0-9]+(\\.[0-9][0-9]?)?")
    return value && regex.test(value)
  },
  message: 'The format must be a decimal with 2 places after the comma',
})
extend('min-length', {
  validate: (value, args) => {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'Please insert at least {length} characters'
})
extend('match', {
  validate: (value, args) => {
    return value === args.value
  },
  params: ['value', 'fieldName'],
  message: '{_field_} must match {fieldName}'
})
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
