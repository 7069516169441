<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="72"
      height="72"
      :fill="bright ? '#F8F8F8' : '#F2F2F7'"
      :storke="bright ? '#F8F8F8' : '#EEE'"
    />
  </svg>
</template>

<script>
export default {
  name: "Rectangle",
  props: {
    bright: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>
