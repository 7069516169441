<template>
  <div class="section">
    <s-notification-list/>
    <h1 v-if="error.statusCode === 404">Page not found</h1>
    <h1 v-else>An error occurred</h1>
    <div class="actions">
      <nuxt-link to="/">Home page</nuxt-link>
      <button class="btn-link link" @click="refresh">Refresh the page</button>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    props: {
      error: {
        type: Object,
        required: true,
      },
    },
    mounted () {
      console.log("Error Page: =>", this.error)
      const cookie = Cookies.get('sybill-apollo-token');
      if (!cookie || this.error.message.includes('Violation')) {
        this.$router.push('/login');
      }
    },
    methods: {
      refresh() {
        location.reload();
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports.scss";

  .actions {
    margin-top: 1rem;
    display: flex;
    gap: 1.5rem;
  }
</style>
