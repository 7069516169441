import Vue from 'vue'

const components = require.context('@/components/utils', true, /[A-Z]\w+\.(vue)$/)

let fileName
for (fileName of components.keys()) {
  const componentConfig = components(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  Vue.component(componentName, componentConfig.default || componentConfig)
}
