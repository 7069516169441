import $notify from '~/plugins/helpers'

const openToast = (message, subtitle) => {
  $notify(message, 'is-danger', true, subtitle)
}

export default ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0]?.type == 'JSONWebTokenExpired') {
      return
    } else {
      graphQLErrors.forEach((e) => {
        let subtitle = ` ${e?.type ? `Type: ${e.type}` : ''} ${e?.code ? `<br/> Code: ${e.code}` : ''} `
        const fieldErrors = e.field_errors || e.fieldErrors
        if (e.nonFieldErrors) {
          e.nonFieldErrors.forEach((e) => {
            openToast(e, subtitle)
          })
        } else if (fieldErrors) {
          Object.keys(fieldErrors).map((fieldName) => openToast(fieldErrors[fieldName].join(', '), subtitle))
        } else if (e.non_field_errors) {
          openToast(e.non_field_errors[0], subtitle)
        } else {
          openToast(e.message, subtitle)
        }
      })
    }
  }
  if (networkError) {
    if (!process.server) {
      openToast('Server error')
    }
  }
}
