<template>
  <svg
    width="120"
    height="32"
    viewBox="0 0 1291 385"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#a)" fill="#035C76">
      <path fill-rule="evenodd" clip-rule="evenodd" d="m337.554 139.218 38.282-30.445c-11.963-24.4417-29.134-45.9669-50.296-63.0704l-38.206 30.5377c22.16 15.3027 39.5 36.4387 50.22 62.9777ZM272.514 88.9144c-11.87-8.1839-24.941-14.4826-38.738-18.6506-26.379-7.9811-54.033-8.0234-80.555 0-13.798 4.168-26.869 10.4667-38.739 18.6506L76.2848 58.3767C55.1147 75.4802 37.952 97.0054 25.9888 121.447l38.2736 30.445c-5.3432 13.392-8.5728 27.536-9.5705 41.918L7 204.631c.17754 27.215 6.2986 54.059 17.9489 78.653l47.6666-10.949c7.1441 12.53 16.1904 23.876 26.8093 33.624l-21.2716 44.039C99.5347 366.823 124.34 378.769 150.837 385l21.161-44.09c14.246 2.223 28.754 2.223 43 0L236.16 385c26.496-6.231 51.302-18.177 72.683-35.002l-21.271-44.039c10.618-9.748 19.665-21.094 26.809-33.624l47.666 10.949c11.651-24.594 17.772-51.438 17.949-78.653l-47.692-10.821c-.997-14.382-4.227-28.526-9.57-41.918-10.568-26.488-28.449-47.954-50.22-62.9776ZM137.377 169.79l-12.792 21.821 66.884 39.466 81.628-81.603-17.94-17.932-67.678 67.712-50.102-29.464ZM233.775 53.3226l.059-48.90934c-26.564-5.88435-54.1-5.88435-80.664 0l.059 48.90934c26.192-7.8035 53.272-7.6767 80.546 0ZM216.874 13.561c-14.829-3.3479-30.41-3.3649-46.753 0v28.72c15.379-4.1004 30.944-4.3371 46.753 0v-28.72Zm-10.322 8.4715c-8.286-.9131-16.986-.9131-26.117 0v7.7697c8.59-1.1076 17.29-1.1752 26.117 0v-7.7697Z"/>
      <path d="M572.719 64.4722c11.814 0 22.611 1.6297 32.388 4.8889 9.778 3.2593 18.028 7.0278 24.75 11.3056v41.8613c-7.333-4.482-15.175-8.658-23.527-12.528-8.352-3.87-17.824-5.806-28.417-5.806-5.296 0-10.593.815-15.889 2.445-5.092 1.63-9.37 4.074-12.833 7.333-3.259 3.259-4.889 7.435-4.889 12.528 0 6.722 2.954 11.815 8.861 15.278 5.907 3.463 13.139 6.926 21.694 10.389l25.056 10.389c14.259 6.111 24.75 13.546 31.472 22.305 6.722 8.759 10.084 18.741 10.084 29.945 0 12.222-3.565 23.018-10.695 32.388-6.926 9.167-16.296 16.399-28.111 21.695-11.611 5.296-24.546 7.944-38.806 7.944-12.018 0-23.527-2.037-34.527-6.111s-20.574-9.065-28.723-14.972v-41.861c8.963 6.518 18.843 12.018 29.639 16.5 10.797 4.481 22.306 6.722 34.528 6.722 8.556 0 15.889-1.63 22-4.889 6.111-3.463 9.167-8.555 9.167-15.278 0-5.296-2.547-9.574-7.639-12.833-4.889-3.463-10.287-6.315-16.195-8.555l-31.472-12.223c-15.889-6.111-26.685-14.055-32.389-23.833-5.5-9.981-8.25-20.981-8.25-33 0-12.426 3.361-23.222 10.084-32.3889 6.926-9.3704 15.889-16.6018 26.889-21.6944 11-5.2963 22.916-7.9445 35.75-7.9445Z"/>
      <path d="M572.719 64.4722c11.814 0 22.611 1.6297 32.388 4.8889 9.778 3.2593 18.028 7.0278 24.75 11.3056v41.8613c-7.333-4.482-15.175-8.658-23.527-12.528-8.352-3.87-17.824-5.806-28.417-5.806-5.296 0-10.593.815-15.889 2.445-5.092 1.63-9.37 4.074-12.833 7.333-3.259 3.259-4.889 7.435-4.889 12.528 0 6.722 2.954 11.815 8.861 15.278 5.907 3.463 13.139 6.926 21.694 10.389l25.056 10.389c14.259 6.111 24.75 13.546 31.472 22.305 6.722 8.759 10.084 18.741 10.084 29.945 0 12.222-3.565 23.018-10.695 32.388-6.926 9.167-16.296 16.399-28.111 21.695-11.611 5.296-24.546 7.944-38.806 7.944-12.018 0-23.527-2.037-34.527-6.111s-20.574-9.065-28.723-14.972v-41.861c8.963 6.518 18.843 12.018 29.639 16.5 10.797 4.481 22.306 6.722 34.528 6.722 8.556 0 15.889-1.63 22-4.889 6.111-3.463 9.167-8.555 9.167-15.278 0-5.296-2.547-9.574-7.639-12.833-4.889-3.463-10.287-6.315-16.195-8.555l-31.472-12.223c-15.889-6.111-26.685-14.055-32.389-23.833-5.5-9.981-8.25-20.981-8.25-33 0-12.426 3.361-23.222 10.084-32.3889 6.926-9.3704 15.889-16.6018 26.889-21.6944 11-5.2963 22.916-7.9445 35.75-7.9445ZM701.969 118.861l44 98.389 42.777-98.389h44.306L720.913 346.5h-44l46.444-84.639-66.611-143h45.223Z"/>
      <path d="m701.969 118.861 44 98.389 42.777-98.389h44.306L720.913 346.5h-44l46.444-84.639-66.611-143h45.223ZM945.496 114.889c13.241 0 25.26 3.361 36.056 10.083 11 6.519 19.658 15.889 25.968 28.111 6.52 12.223 9.78 26.889 9.78 44s-3.26 31.676-9.78 43.695c-6.31 12.018-14.764 21.185-25.357 27.5-10.389 6.315-21.694 9.472-33.917 9.472-11.407 0-21.389-2.954-29.944-8.861-8.556-6.111-15.074-13.343-19.556-21.695v25.973h-38.805V53.1667h38.805v83.1113c5.704-6.519 12.732-11.713 21.084-15.584 8.555-3.87 17.111-5.805 25.666-5.805Zm-7.944 38.194c-7.537 0-14.565 1.732-21.083 5.195-6.315 3.259-12.223 7.842-17.723 13.75v22c-.203 9.37 1.528 17.416 5.195 24.139 3.87 6.518 8.861 11.611 14.972 15.277 6.111 3.463 12.63 5.195 19.556 5.195 11.611 0 20.879-3.972 27.805-11.917 7.13-8.148 10.695-18.028 10.695-29.639 0-7.74-1.63-14.87-4.889-21.389-3.26-6.722-7.843-12.12-13.75-16.194-5.908-4.278-12.834-6.417-20.778-6.417Z"/>
      <path d="M945.496 114.889c13.241 0 25.26 3.361 36.056 10.083 11 6.519 19.658 15.889 25.968 28.111 6.52 12.223 9.78 26.889 9.78 44s-3.26 31.676-9.78 43.695c-6.31 12.018-14.764 21.185-25.357 27.5-10.389 6.315-21.694 9.472-33.917 9.472-11.407 0-21.389-2.954-29.944-8.861-8.556-6.111-15.074-13.343-19.556-21.695v25.973h-38.805V53.1667h38.805v83.1113c5.704-6.519 12.732-11.713 21.084-15.584 8.555-3.87 17.111-5.805 25.666-5.805Zm-7.944 38.194c-7.537 0-14.565 1.732-21.083 5.195-6.315 3.259-12.223 7.842-17.723 13.75v22c-.203 9.37 1.528 17.416 5.195 24.139 3.87 6.518 8.861 11.611 14.972 15.277 6.111 3.463 12.63 5.195 19.556 5.195 11.611 0 20.879-3.972 27.805-11.917 7.13-8.148 10.695-18.028 10.695-29.639 0-7.74-1.63-14.87-4.889-21.389-3.26-6.722-7.843-12.12-13.75-16.194-5.908-4.278-12.834-6.417-20.778-6.417ZM1086.66 38.5c7.34 0 13.65 2.6481 18.95 7.9444 5.29 5.0926 7.94 11.2037 7.94 18.3334 0 6.9259-2.65 13.037-7.94 18.3333-5.3 5.2963-11.61 7.9445-18.95 7.9445-7.33 0-13.65-2.6482-18.94-7.9445-5.09-5.2963-7.64-11.4074-7.64-18.3333 0-7.1297 2.55-13.2408 7.64-18.3334 5.29-5.2963 11.61-7.9444 18.94-7.9444Zm22.61 80.361v154.306h-38.8V151.861h-24.75v-33h63.55Z"/>
      <path d="M1086.66 38.5c7.34 0 13.65 2.6481 18.95 7.9444 5.29 5.0926 7.94 11.2037 7.94 18.3334 0 6.9259-2.65 13.037-7.94 18.3333-5.3 5.2963-11.61 7.9445-18.95 7.9445-7.33 0-13.65-2.6482-18.94-7.9445-5.09-5.2963-7.64-11.4074-7.64-18.3333 0-7.1297 2.55-13.2408 7.64-18.3334 5.29-5.2963 11.61-7.9444 18.94-7.9444Zm22.61 80.361v154.306h-38.8V151.861h-24.75v-33h63.55ZM1196.97 53.1667V273.167h-38.81V53.1667h38.81Z"/>
      <path d="M1196.97 53.1667V273.167h-38.81V53.1667h38.81ZM1283.14 53.1667V273.167h-38.81V53.1667h38.81Z"/>
      <path d="M1283.14 53.1667V273.167h-38.81V53.1667h38.81Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h1290.14v385H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<style scoped>

</style>
