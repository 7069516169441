var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"s-tag",class:{
    'is-large':_vm.isLarge,
    'hourly':_vm.isHourly,
    'flatrate':_vm.isFlatrate,
    'non-billable':_vm.isNonbillable,
    'completed':_vm.isDone,
    'draft':_vm.isDraft,
    'in-progress':_vm.isProgress,
    'pending':_vm.isPending,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }