<template>
  <div>
    <div class="s-select-with-search">
      <div
        class="s-select s-select-with-search_input"
        :class="{'has-prepend': prepend}"
        @click="isOpen = !isOpen"
      >
        <span v-if="prepend" class="prepend">{{ prepend }}</span>
        <input
          v-model="searchQuery"
          :placeholder="placeholder"
          class="d-input-base"
          @input="isOpen = true"
        />
        <span class="icomoon-chevron-down icon" :class="{'is-open': isOpen}"/>
      </div>

      <div v-if="isOpen" class="outside" @click="isOpen = false"></div>

      <div
        v-if="isOpen"
        class="s-select-with-search_options"
        @click="isOpen = false"
      >
        <button
          v-for="item in filteredOptions" :key="itemText ? item[itemText] : item"
          class="s-select-with-search_option"
          @click.prevent="selectItem(item)"
        >
          {{ itemText ? item[itemText] : item }}
          <span v-if="itemValue ? value === item[itemValue] : value === item" class="icomoon-check check"></span>
        </button>
        <p v-if="!filteredOptions.length" class="empty-results">No option found!</p>
      </div>
      <p
        v-if="message"
        class="s-select-with-search_message"
      >
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prepend: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isOpen: false,
      searchQuery: '',
    }
  },
  computed: {
    filteredOptions() {
      const selectedOption = this.options.find(option => {
        return this.itemText ? option[this.itemText] === this.searchQuery : option === this.searchQuery;
      });
      if (!this.searchQuery || selectedOption) {
        return this.options;
      }

      return this.options.filter(option => {
        const text = this.getOptionText(option).toLowerCase();
        return text.includes(this.searchQuery.toLowerCase());
      });
    }
  },
  watch: {
    value() {
      const selectedOption = this.getSelectedOption()
      this.searchQuery = this.getOptionText(selectedOption)
    },
    isOpen(newValue) {
      if (!newValue) {
        const selectedOption = this.getSelectedOption()
        this.searchQuery = this.getOptionText(selectedOption)
      }
    }
  },
  mounted() {
    const selectedOption = this.getSelectedOption();
    this.searchQuery = this.getOptionText(selectedOption)
  },

  methods: {
    getSelectedOption() {
      return this.options.find(option => {
        return this.itemValue ? option[this.itemValue] === this.value : option === this.value;
      });
    },

    getOptionText(option) {
      return this.itemText ? option?.[this.itemText] : option;
    },

    selectItem(option) {
      this.$emit('input', this.itemValue ? option[this.itemValue] : option);
      this.searchQuery = this.itemText ? option[this.itemText] : option;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/imports.scss";
.s-select-with-search {
  position: relative;

  .outside {
    position: fixed;
    inset: 0;
  }

  .s-select-with-search_input {
    &.has-prepend {
      input {
        padding-left: rem(32px);
      }
    }

    .prepend {
      position: absolute;
      top: rem(16px);
      left: rem(16px);
      color: $grey-extra-7;
      font-size: 18px;
    }

    input {
      width: 100%;
    }

    .icon {
      position: absolute;
      top: rem(16px);
      right: rem(6px);

      &.is-open {
        transform: rotate(180deg);
      }
    }
  }

  .s-select-with-search_options {
    position: absolute;
    top: rem(60px);
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 10;
    max-height: 200px;
    overflow: auto;
    border: 1px solid $border-default;

    .s-select-with-search_option {
      all: unset;
      cursor: pointer;
      padding: rem(16px) rem(12px);
      border-bottom: 1px solid $border-soft-color;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $background-light-hover;
      }

      .check {
        color: $alert-success;
      }
    }
  }

  .s-select-with-search_message {
    margin-top: rem(4px);
  }

  .empty-results {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: $grey-extra-5;
  }
}
</style>
