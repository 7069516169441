<template>
  <div
    class="s-table"
    :class="{
      'is-stripped':isStripped,
      'is-compact':isCompact,
      'hover-row':hoverRow,
    }">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-table {

    .s-table-header,
    .s-table-row {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    }

    &.is-stripped {
      .s-table-row:nth-of-type(odd) {
        background-color: $smoke;
      }
    }

    &.is-compact {
      .s-table-row {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    &.hover-row {
      .s-table-row:not(.is-total) {
        &:hover {
          background: $smoke;
        }
      }
    }
  }

</style>

<script>
  export default {
    props: {
      isStripped: {
        type: Boolean,
        default: false,
      },
      isCompact: {
        type: Boolean,
        default: false,
      },
      hoverRow: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
