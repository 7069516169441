<template>
  <div
    class="s-checkbox"
    :class="{
      'has-error':hasError,
      'is-disabled':isDisabled,
  }">
    <label
      v-if="label"
      :class="{
        'has-error':hasError,
        'is-optional':isOptional
        }">
      {{ label }}
      <input
        v-model="computedValue"
        type="checkbox"
        :value="label"
        :disabled="isDisabled"
        :isOptional="isOptional"
        :has-error="hasError"
      />
      <span class="checkmark" :class="isPartial ? 'partial' : ''"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SCheckbox',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    cData: {
      type: [String, Boolean, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: null,
    },
    isPartial: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value;
        this.$emit('input', value)
        this.$emit('onChange')
      }
    }
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    value (value) {
      this.newValue = value
    }
  },
  methods: {
    focus () {
      // MacOS FireFox and Safari do not focus when clicked
      this.$refs.input.focus()
    },
  }
}
</script>


