<template>
  <div class="custom-inline-fields">
    <validation-provider v-slot="{ errors, validate }" rules="is-positive-number" slim>
      <b-field :type="{'is-danger': errors[0]}"
               :message="errors[0]"
               class="has-append">
        <div class="has-append-wrapper">
          <b-input
            v-model="hours"
            type="number"
            :min="0"
            class="is-short-1"
            oninput="validity.valid||(value='')"
            @change.native="e => updateTotal(e, validate)">
          </b-input>
          <span>h</span>
        </div>
      </b-field>
    </validation-provider>
    <validation-provider v-slot="{ errors, validate }" rules="is-positive-number" slim>
      <b-field :type="{'is-danger': errors[0]}"
               :message="errors[0]"
               class="has-append">
        <div class="has-append-wrapper">
          <b-input
            v-model="minutes"
            type="number"
            :min="0"
            class="is-short-1"
            oninput="validity.valid||(value='')"
            @change.native="e => updateTotal(e, validate)">
          </b-input>
          <span>m</span>
        </div>
      </b-field>
    </validation-provider>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      duration: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        hours: null,
        minutes: null,
      }
    },
    watch: {
      duration (newValue) {
        this.setModels(newValue)
      }
    },
    mounted () {
      this.setModels(this.duration)
    },
    methods: {
      setModels (duration) {
        if (duration) {
          this.hours = this.duration.format('HH:mm', { trim: false }).split(':')[0]
          this.minutes = this.duration.format('HH:mm', { trim: false }).split(':')[1]
        }
      },
      async updateTotal (e, v) {
        const value = e.target.value
        const res = await v(value)
        if (res.valid) {
          console.log(value, this.minutes, this.hours)
          const duration = moment.duration({
            minutes: parseInt(this.minutes),
            hours: parseInt(this.hours),
          })
          this.$emit('update', duration)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";


</style>
