<template>
  <div v-if="entry">
    <s-tooltip
      v-if="entry.isRunning"
      label="Pause timer"
      animated
      type="is-dark">
      <timer-button
        is-pause
        class="mr10"
        @click.stop="pauseTimer()">
      </timer-button>
    </s-tooltip>
    <s-tooltip
      v-else
      label="Start timer"
      animated
      type="is-dark">
      <timer-button
        is-play
        class="mr10"
        @click.stop="startTimer(task)">
      </timer-button>
    </s-tooltip>
    <s-tooltip
      label="Finish timer"
      animated
      type="is-dark">
      <timer-button
        is-stop
        :disabled="commentRequired && !comment"
        @click.stop="pauseTimer(true)">
      </timer-button>
    </s-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      task: {
        type: Object,
        required: true
      },
      comment: {
        type: String,
        default: ''
      },
      commentRequired: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      entry () {
        return this.$store.getters['timer/getMyEntryByTaskId'](this.task.id)
      }
    },
    methods: {
      async startTimer(task) {
        await this.$store.dispatch('timer/startTimer', task.id)
      },
      async pauseTimer(stop = false) {
        if(stop) {
          await this.$store.dispatch('timer/pauseTimer', { stop, comment: this.comment, entry: this.entry })
          this.$notify('Task successfully saved', 'is-success', true);
          this.$emit('close')
          await this.$store.dispatch('timer/getMyEntries', this.$route.params.organisation)
        } else {
          await this.$store.dispatch('timer/pauseTimer', { stop, comment: this.comment })
        }
      }
    }
  }
</script>
