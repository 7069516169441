var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"s-btn",class:{
    'is-small':_vm.isSmall,
    'is-large':_vm.isLarge,
    'is-primary':_vm.isPrimary,
    'is-danger':_vm.isDanger,
    'is-fullwidth':_vm.isFullwidth
  },attrs:{"disabled":_vm.isDisabled,"type":_vm.nativeType}},_vm.$listeners),[_c('span',{class:{
    'has-i-edit':_vm.hasIEdit,
    'has-i-add':_vm.hasIAdd,
    'has-i-add-time':_vm.hasIAddTime,
    'has-i-check':_vm.hasICheck,
    'has-i-trash':_vm.hasITrash,
    'has-i-pdf':_vm.hasIPdf,
  }}),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }