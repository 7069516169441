<template>
  <span
    class="expandable-input"
    :class="{
      'is-align-right':alignRight,
      'is-small':isSmall,
      'is-large':isLarge,
      }"
    role="textbox"
    contenteditable>
    <slot></slot>
  </span>
</template>

<style lang="scss" scoped>
@import "~assets/scss/imports";

.expandable-input {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  resize: vertical;
  min-height: $control-height-small;
  border: 1px dashed $form-border-color;
  line-height: 20px;
  background: $white;
  padding-left: $form-padding-sm;
  padding-right: $form-padding-sm;

  &:focus {
    outline: none;
    border-color: $form-focus-border-color;
  }

  &.is-align-right {
    text-align: right;
  }
}
</style>

<script>
export default {
  props: {
    alignRight: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
