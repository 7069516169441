var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-input",class:{
    'is-search': _vm.isSearch,
    'has-append': _vm.append,
    'has-inner-append': _vm.innerAppend,
    'has-reveal-password': _vm.revealPassword,
    'has-icon': _vm.icon,
  }},[(_vm.inputType !== 'textarea')?_c('input',_vm._b({attrs:{"type":_vm.inputType,"placeholder":_vm.placeholder,"required":!_vm.isOptional,"disabled":_vm.isDisabled},domProps:{"value":_vm.computedValue},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInput}},'input',_vm.$attrs,false)):_c('textarea',_vm._b({attrs:{"type":_vm.inputType,"placeholder":_vm.placeholder,"required":!_vm.isOptional,"disabled":_vm.isDisabled},domProps:{"value":_vm.computedValue},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInput}},'textarea',_vm.$attrs,false)),_vm._v(" "),(_vm.innerAppend)?_c('span',{staticClass:"inner-append"},[_vm._v(_vm._s(_vm.innerAppend))]):_vm._e(),_vm._v(" "),(_vm.append)?_c('span',{staticClass:"append"},[_vm._v(_vm._s(_vm.append))]):_vm._e(),_vm._v(" "),(_vm.revealPassword)?_c('span',{staticClass:"reveal-password",on:{"click":_vm.toggleShowPassword}},[_vm._v("\n    "+_vm._s(_vm.showPassword ? 'Hide' : 'Show')+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.icon)?_c('span',{staticClass:"input-icon"},[_c('i',{class:`icomoon-${_vm.icon}`})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }