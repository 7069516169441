<template>
  <validation-observer ref="observer">
    <modal-wrapper>
      <s-card-header>
        <h1>{{ title }}</h1>
      </s-card-header>
      <s-card-content>
        <section>
          <Multiselect
            v-model="selectedProject"
            :options="projectGroupByCustomer"
            group-values="list"
            group-label="customerName"
            track-by="name"
            label="name"
            label-wrapper="Project"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name }} ({{ option.customer.name }})
            </template>
          </Multiselect>

          <Multiselect
            v-model="selectedTask"
            :options="taskGroupBySegment"
            group-values="list"
            group-label="segmentName"
            track-by="name"
            label="name"
            :disabled="taskGroupBySegment.length === 0"
            label-wrapper="Task"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name }} ({{
                option.segment ? option.segment.name : entry ? entry.segment.name : task.segment.name
              }})
            </template>
          </Multiselect>
        </section>

        <section style="margin-top: 24px">
          <validation-provider v-slot="{ errors }" name="date" rules="required">
            <b-field label="Booking date" :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <b-datepicker
                v-model="bookDate"
                :max-date="maxDate"
                name="date"
                placeholder="Click to select..."
                :date-formatter="formatDates"
                :first-day-of-week="1"
              >
              </b-datepicker>
            </b-field>
          </validation-provider>
        </section>

        <section v-if="entryData">
          <s-table class="timings">
            <s-table-header>
              <s-table-cell>Start</s-table-cell>

              <s-table-cell>End</s-table-cell>

              <s-table-cell></s-table-cell>

              <s-table-cell>Duration</s-table-cell>

              <s-table-cell></s-table-cell>
            </s-table-header>

            <s-table-row v-for="timing in timings.slice()" :key="timing.id">
              <s-table-cell>
                <validation-provider
                  v-slot="{ errors, validate }"
                  :name="`${timing.id}-start`"
                  rules="is-date|required"
                  class="grid-time"
                >
                  <b-field>
                    <b-datepicker
                      v-model="timing.startDate"
                      :max-date="maxDate"
                      icon="calendar-today"
                      editable
                      :date-formatter="formatDates"
                      @blur="updateTime(timing.startDate, timing, validate)"
                    >
                    </b-datepicker>
                  </b-field>

                  <b-field>
                    <b-timepicker
                      v-model="timing.startTime"
                      :time-parser="parseTime"
                      :class="{ 'is-danger': errors[0] }"
                      icon="clock"
                      :message="errors[0]"
                      placeholder="Start"
                      :name="`${timing.id}-start`"
                      hour-format="24"
                      editable
                      @blur="updateTime(timing.startTime, timing, validate)"
                    >
                    </b-timepicker>
                  </b-field>
                </validation-provider>
              </s-table-cell>

              <s-table-cell v-if="!!timing.end">
                <validation-provider
                  v-slot="{ errors, validate }"
                  :name="`${timing.id}-end`"
                  rules="is-date"
                  class="grid-time"
                >
                  <b-field>
                    <b-datepicker
                      v-model="timing.endDate"
                      :max-date="maxDate"
                      icon="calendar-today"
                      editable
                      :date-formatter="formatDates"
                      @blur="updateTime(timing.endDate, timing, validate)"
                    >
                    </b-datepicker>
                  </b-field>

                  <b-field>
                    <b-timepicker
                      v-model="timing.endTime"
                      :time-parser="parseTime"
                      :class="{ 'is-danger': errors[0] }"
                      icon="clock"
                      :message="errors[0]"
                      placeholder="End"
                      :name="`${timing.id}-end`"
                      hour-format="24"
                      editable
                      @blur="updateTime(timing.endTime, timing, validate)"
                    >
                    </b-timepicker>
                  </b-field>
                </validation-provider>
              </s-table-cell>

              <s-table-cell v-else-if="entryData.isRunning">
                <timer-dot is-running></timer-dot>
              </s-table-cell>

              <s-table-cell>
                <icon-exchange></icon-exchange>
              </s-table-cell>

              <s-table-cell>
                <Duration
                  v-if="!!timing.end"
                  :duration="getDuration(timing.totalSeconds)"
                  @update="(duration) => updateTotal(timing, duration)"
                >
                </Duration>

                <div v-else-if="entryData.isRunning">
                  <s-number>
                    {{ currentTimer | formatActiveTimer }}
                  </s-number>
                </div>
              </s-table-cell>

              <s-table-cell is-pull-right>
                <s-icon-button :disabled="timing.loading" is-delete @click="deleteTimeEntry(timing)"></s-icon-button>
              </s-table-cell>
            </s-table-row>

            <s-table-row class="total">
              <s-table-cell>
                <button v-if="!task && entryData && !edit" class="btn-link link-plus mt10" @click="addTimeEntry">
                  Add time interval
                </button>

                <h2 v-else>Total</h2>
              </s-table-cell>

              <s-table-cell></s-table-cell>

              <s-table-cell></s-table-cell>

              <s-table-cell>
                <s-number is-large>
                  {{ getTotalSeconds() | formatDuration('H [h] m [m] s [s]') }}
                </s-number>
              </s-table-cell>

              <s-table-cell></s-table-cell>
            </s-table-row>
          </s-table>
        </section>

        <section>
          <validation-provider v-slot="{ errors }" name="comment" vid="comment" :rules="commentValidation" slim>
            <s-field
              label="Comment"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
              :required="commentRequired"
              immediate
            >
              <s-input v-model="comment" type="textarea" name="comment" placeholder="What did you work on?"> </s-input>
            </s-field>
          </validation-provider>
        </section>
      </s-card-content>

      <s-card-footer v-if="entryData" :class="{ 'justify-space-between': !!edit }">
        <s-button v-if="!task" has-i-trash is-large is-danger @click="deleteEntry"> Delete timer </s-button>

        <s-button v-if="task" is-large @click="cancel"> Cancel </s-button>

        <MinimalTimer
          v-if="edit"
          ref="minimalTimer"
          :task="entryData.task"
          :comment="comment"
          :comment-required="commentRequired"
          @close="$emit('close')"
        />
        <s-button
          v-if="edit"
          is-large
          is-primary
          has-i-edit
          :is-disabled="commentRequired && !comment"
          @click="saveTimer"
        >
          Save changes
        </s-button>

        <s-button v-else is-primary is-large :is-disabled="commentRequired && !comment" @click="finishTimer">
          {{ saveLabel }}
        </s-button>
      </s-card-footer>

      <s-card-footer v-else>
        <s-button tag="input" value="Create entry" type="is-primary" @click="createEntry" />
        <s-button tag="input" value="Cancel" type="is-link" @click="cancel" />
      </s-card-footer>
    </modal-wrapper>
  </validation-observer>
</template>

<style lang="scss" scoped>
@import '~assets/scss/imports';

.s-table.timings {
  margin: 20px 0;

  .s-table-header,
  .s-table-row {
    grid-template-columns: 2fr 2fr 0.5fr 2fr 1fr;
    grid-gap: 10px;
  }

  .s-table-row {
    background-color: $cloud;

    &.total {
      background-color: $mint;
    }
  }
}

.grid-time {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  .field {
    margin: 0 rem(4px);
  }
}

::v-deep .datepicker,
::v-deep .timepicker,
::v-deep .custom-inline-fields {
  input {
    border: none;
    border-bottom: rem(1px) dashed gray;
    border-radius: 0;
    background: transparent;
  }
}

.justify-space-between {
  justify-content: space-between !important;
}
</style>

<script>
import createTimeEntryMutation from '~/graphql/mutations/createTimeEntry.graphql'
import updateTimeEntryMutation from '~/graphql/mutations/updateTimeEntry.graphql'
import deleteTimeEntryMutation from '~/graphql/mutations/deleteTimeEntry.graphql'
import deleteEntryMutation from '~/graphql/mutations/deleteEntry.graphql'
import updateEntryMutation from '~/graphql/mutations/updateEntry.graphql'
import createEntryMutation from '~/graphql/mutations/createEntry.graphql'
import startTimerMutation from '~/graphql/mutations/startTimer.graphql'
import projectsQuery from '~/graphql/queries/projects.graphql'
import MinimalTimer from '~/components/dashboard/MinimalTimer'
import entriesQuery from '~/graphql/queries/entries.graphql'
import getEntryQuery from '~/graphql/queries/entry.graphql'
import tasksQuery from '~/graphql/queries/tasks.graphql'
import Duration from '~/components/dashboard/Duration'
import moment from 'moment'
import * as _ from 'lodash'
import SNumber from '~/components/utils/SNumber'
import STable from '~/components/utils/STable'
import STableCell from '~/components/utils/STableCell'

export default {
  components: {
    STableCell,
    STable,
    SNumber,
    Duration,
    MinimalTimer,
  },
  timers: {
    tick: {
      time: 1000,
      autostart: false,
      repeat: true,
    },
  },
  filters: {
    formatActiveTimer(value) {
      if (value == null) return ''
      const m = moment.duration(value, 'seconds')
      if (m.asMilliseconds()) {
        return m.format('H[h] m[m] s[s]', { minValue: 1, trim: false, trunc: true })
      }
      return m.format('H[h] m[m] s[s]', { trim: false })
    },
  },
  props: {
    entry: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    commentRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: false,
      newEntryId: null,
      projectFilter: '',
      segmentFilter: '',
      selectedSegment: '',
      taskFilter: '',
      bookDate: moment().toDate(),
      maxDate: moment().toDate(),
      invalid: true,
      finished: false,
      comment: '',
      selectedProject: this.entry ? this.entry.project : this.task ? this.task.project : '',
      selectedTask: this.entry
        ? this.entry.task
        : this.task
        ? {
            id: this.task.id,
            name: this.task.name,
          }
        : '',
      entryData: JSON.parse(JSON.stringify(this.entry)),
      time: window.time || null,
    }
  },
  apollo: {
    projects: {
      query: projectsQuery,
      variables() {
        return {
          organisation: this.$route.params.organisation,
        }
      },
      update(data) {
        return data.projects ? data.projects : []
      },
    },
    tasks: {
      query: tasksQuery,
      variables() {
        return {
          organisationId: this.$route.params.organisation,
          projectId: this.selectedProject ? this.selectedProject.id : this.entry ? this.entry.projectId : null,
        }
      },
      update(data) {
        return data.tasks.items ? data.tasks.items : []
      },
      skip: true,
    },
    entries: {
      query: entriesQuery,
      variables() {
        return {
          organisationId: this.$route.params.organisation,
          finished: false,
          taskId: this.entry ? this.entry.taskId : null,
          onlyMyEntries: true,
        }
      },
      skip: true,
    },
  },
  computed: {
    selectedDate() {
      return this.$store.getters['timer/getSelectedDate']
    },
    projectGroupByCustomer() {
      return _.values(
        _.mapValues(
          _.groupBy(this.projects, (e) => e.customerId),
          (item) => {
            return {
              customerName: item[0].customer.name,
              list: item,
            }
          }
        )
      )
    },
    taskGroupBySegment() {
      return _.values(
        _.mapValues(
          _.groupBy(this.tasks, (e) => e.segmentId),
          (item) => {
            return {
              segmentName: item[0].segment.name,
              list: item,
            }
          }
        )
      )
    },
    timings() {
      this.flag
      if (this.entryData) {
        let temp = this.updateTimings(this.entryData.timings)
        if (this.selectedDate && temp[0]) {
          temp[0].startDate = this.selectedDate
          temp[0].endDate = this.selectedDate
        }
        return temp
      }
      return []
    },
    commentValidation() {
      if (this.segment) {
        if (this.segment.commentsRequired) {
          return 'required'
        }
      }
      return ''
    },
    currentTimer() {
      if (this.time) {
        return this.time
      }
      return 0
    },
    isTimerRunning() {
      return this.timings.some((p) => !p.end)
    },
    saveLabel() {
      return this.task ? 'Save' : 'Save and Finish'
    },
    runningId() {
      return this.$store.getters['timer/getRunningId']
    },
  },
  watch: {
    selectedProject() {
      this.selectedTask = null
      this.$apollo.queries.tasks.skip = false
      this.$apollo.queries.tasks.refetch()
    },
    entryData(entry) {
      if (entry && entry.isRunning) {
        this.$timer.start('tick')
      } else {
        this.$timer.stop('tick')
      }
    },
    entries() {
      this.time = this.entries.length ? 0 : this.time
      this.entryData = this.entries.length ? this.entries[0] : this.entryData
    },
    runningId() {
      this.time = 0
      this.reloadEntry()
    },
    bookDate(newVal) {
      this.flag = !this.flag
      if (this.timings.length && this.task) {
        this.timings[0].startDate = newVal
        this.timings[0].endDate = newVal
      }
    },
  },
  async mounted() {
    if (this.selectedDate) this.bookDate = this.selectedDate
    this.setFields()
    if (this.task) {
      this.createEntry()
    }
    // Handling timer when entry is running
    if (this.entryData) {
      if (this.entryData.isRunning && this.edit) {
        let start = this.entryData.timings[this.entryData.timings.length - 1].start
        let startInSeconds = moment(start).diff(moment().startOf('day'), 'seconds')
        let now = moment().diff(moment().startOf('day'), 'seconds')
        this.time = now - startInSeconds
        this.$timer.start('tick')
        this.$watch(
          () => {
            return this.$refs.minimalTimer.entries
          },
          (val) => {
            if (!val.length) {
              this.reloadEntry()
            }
          }
        )
      }
    }
  },
  methods: {
    parseTime(time) {
      if (typeof time === 'string' && time.match('^([01]?[0-9]|2[0-3])[0-5][0-9]$')) {
        return moment(time, 'HHmm').toDate()
      } else if (typeof time === 'string' && time.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]$')) {
        return moment(time, 'HH:mm').toDate()
      } else if (typeof time === 'string') {
        return moment().toDate()
      }
      return moment(time).toISOString(true)
    },
    formatDates(date) {
      return `${moment(date).format('DD.MM.YYYY')}`
    },
    updateTimer() {
      this.$apollo.queries.entries.skip = false
      this.$apollo.queries.entries.refetch()
      this.$store.dispatch('timer/getMyEntries', this.$route.params.organisation)
    },
    async pauseTimer() {
      try {
        await this.$apollo.mutate({
          mutation: updateEntryMutation,
          variables: {
            id: this.entry.id,
            comment: this.comment,
            bookDate: moment(this.bookDate).format('YYYY-MM-DD'),
            taskId: this.entry.taskId,
            finished: false,
          },
        })
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
        }
      }
    },
    async startTimer() {
      if (!this.isTimerRunning) {
        try {
          await this.$apollo.mutate({ mutation: startTimerMutation, variables: { taskId: this.entry.taskId } })
        } catch (e) {
          if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
            this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
          }
        }
      }
    },
    getTotalSeconds() {
      if (this.timings.length) {
        let totalSeconds
        if (this.timings.length > 1) {
          totalSeconds = this.timings.map((e) => e.totalSeconds).reduce((a, b) => a + b)
        } else {
          totalSeconds = this.timings[0].totalSeconds
        }
        return totalSeconds
      }
      return 0
    },
    tick() {
      this.time = moment.duration(this.time, 'seconds').add(moment.duration(1, 's'))
    },
    getDuration(seconds) {
      return moment.duration(seconds, 'seconds')
    },
    setFields() {
      if (this.entryData) {
        this.bookDate = moment(this.entryData.bookDate).toDate()
        this.comment = this.entryData.comment
        this.selectedSegment = this.entryData.segment
        this.taskFilter = this.entryData.task.name
        this.segmentFilter = this.entryData.segment.name
        this.projectFilter = this.entryData.project.name
        this.finished = this.entryData.finished
      } else {
        // When there is not entry to begin with we set the flag to finish. in this case the user wants to add some
        // time not start a timer
        this.finished = true
      }
    },
    format(time) {
      if (time) {
        return moment(time).format('HH:mm')
      }
      return ''
    },
    updateTimings(timings) {
      const data = JSON.parse(JSON.stringify(timings))
      data.map((p) => {
        p.startDate = p.start ? moment(p.start.split('T')[0]).toDate() : null
        p.startTime = p.start ? moment(p.start).toDate() : null
        p.endDate = p.end ? moment(p.end.split('T')[0]).toDate() : null
        p.endTime = p.end ? moment(p.end).toDate() : null
        p.loading = false
      })
      return data
    }, 
    async updateTime(time, timeEntry, validate) {
      this.flag = !this.flag
      timeEntry.startDate = time
      timeEntry.endDate = time
      const res = await validate(time)
      if (res.valid && this.validateDateTimePicker(timeEntry)) {
        const data = {}

        if (timeEntry.startTime && timeEntry.startDate) {
          data['start'] = moment(
            moment(timeEntry.startDate).format('YYYY-MM-DD') + ' ' + moment(timeEntry.startTime).format('HH:mm')
          ).format()
        } else {
          data['start'] = timeEntry.startTime
            ? moment(timeEntry.startTime, 'HH:mm').format()
            : timeEntry.startDate
            ? moment(timeEntry.startDate, 'HH:mm').format()
            : null
        }

        if (timeEntry.endTime && timeEntry.endDate) {
          data['end'] = moment(
            moment(timeEntry.endDate).format('YYYY-MM-DD') + ' ' + moment(timeEntry.endTime).format('HH:mm')
          ).format()
        } else {
          data['end'] = timeEntry.endTime
            ? moment(timeEntry.endTime, 'HH:mm').format()
            : timeEntry.endDate
            ? moment(timeEntry.endDate, 'HH:mm').format()
            : null
        }
        await this.updateTimeEntry(timeEntry, data)
      }
    },
    validateDateTimePicker(timeEntry) {
      let combineFirstDate = timeEntry.startTime ? moment(timeEntry.startTime) : moment(timeEntry.startDate)
      if (timeEntry.startTime && timeEntry.startDate) {
        combineFirstDate = moment(
          moment(timeEntry.startDate).format('YYYY-MM-DD') + ' ' + moment(timeEntry.startTime).format('HH:mm')
        ).toDate()
      }

      let combineEndDate = timeEntry.endTime ? moment(timeEntry.endTime) : moment(timeEntry.endDate)
      if (timeEntry.endTime && timeEntry.endDate) {
        combineEndDate = moment(
          moment(timeEntry.endDate).format('YYYY-MM-DD') + ' ' + moment(timeEntry.endTime).format('HH:mm')
        ).toDate()
      }

      const duration = moment(combineEndDate).diff(moment(combineFirstDate), 'hours')

      if (moment(combineEndDate).isBefore(moment(combineFirstDate))) {
        this.$notify('End time should be more than start time', 'is-danger', true)
        timeEntry.totalSeconds = 0
        return false
      }
      if (duration >= 24) {
        this.$notify('There are only 24hrs in a day! ⏳', 'is-danger', true)
        timeEntry.totalSeconds = 0
        return false
      }
      return true
    },
    updateTotal: _.debounce(function (timeEntry, duration) {
      const start = timeEntry.start
      const data = {}
      if (duration) {
        data['end'] = moment(start).add(moment.duration(duration).asSeconds(), 'seconds').format()
      } else {
        data['end'] = null
      }

      this.updateTimeEntry(timeEntry, data)
    }, 600),
    async updateTimeEntry(timeEntry, data) {
      try {
        const response = await this.$apollo.mutate({
          mutation: updateTimeEntryMutation,
          variables: {
            id: timeEntry.id,
            start: timeEntry.start,
            ...data,
          },
        })
        this.entryData.timings = this.timings.map((te) => {
          if (te.id === timeEntry.id) {
            let data = response.data.updateTimeEntry
            data.startDate = data.start ? moment(data.start.split('T')[0]).toDate() : null
            data.startTime = data.start ? moment(data.start).toDate() : null
            data.endDate = data.end ? moment(data.end.split('T')[0]).toDate() : null
            data.endTime = data.end ? moment(data.end).toDate() : null
            return data
          }
          return te
        })
      } catch (e) {
        if (e.graphQLErrors.length && e.graphQLErrors[0]?.fieldErrors) {
          console.log(e.graphQLErrors[0].fieldErrors)
        }
      }
    },
    async reloadEntry() {
      const response = await this.$apollo.query({
        query: getEntryQuery,
        variables: {
          id: this.entryData.id,
        },
        fetchPolicy: 'no-cache',
      })
      this.entryData.timings = this.updateTimings(response.data.entry.timings)
    },
    async deleteTimeEntry(timing) {
      timing.loading = true
      this.$forceUpdate()
      this.$buefy.dialog.confirm({
        title: 'Delete time interval',
        message: 'Are you sure? This action cannot be undone.',
        confirmText: 'Yes, delete interval',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$apollo.mutate({
              mutation: deleteTimeEntryMutation,
              variables: {
                id: timing.id,
              },
            })
            await this.reloadEntry()
          } catch (e) {
            timing.loading = false
            this.$forceUpdate()
            console.log(e)
          }
        },
        onCancel: () => {
          timing.loading = false
          this.$forceUpdate()
        },
      })
    },
    async deleteEntry() {
      this.$buefy.dialog.confirm({
        title: 'Remove timer',
        message: 'Are you sure? This action cannot be undone.',
        confirmText: 'Yes, delete timer!',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.$apollo.mutate({
            mutation: deleteEntryMutation,
            variables: {
              id: this.entryData.id,
            },
          })
          this.updateTimer()
          this.$emit('close')
        },
      })
    },
    async addTimeEntry() {
      try {
        const response = await this.$apollo.mutate({
          mutation: createTimeEntryMutation,
          variables: {
            entryId: this.entryData.id,
            start: moment().format(),
            end: moment().format(),
          },
        })
        this.entryData.timings.push(response.data.createTimeEntry)
        requestAnimationFrame(() => {
          this.$refs.observer.reset()
        })
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          console.log(e.graphQLErrors[0].fieldErrors)
        }
      }
    },
    saveTimer() {
      this.finished = false
      if (this.$refs.minimalTimer) {
        this.finished = !this.$store.getters['timer/getMyEntryByTaskId'](this.entry.taskId) ? true : false
      }
      this.save()
    },
    finishTimer() {
      this.finished = true
      this.save()
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        if (this.getDuration(this.getTotalSeconds()).asHours() >= 24) {
          this.$notify('There are only 24hrs in a day! ⏳', 'is-danger', true)
        } else {
          let taskId
          if (this.selectedTask) {
            taskId = this.selectedTask.id
          } else {
            taskId = this.task.id
          }
          try {
            await this.$apollo.mutate({
              mutation: updateEntryMutation,
              variables: {
                id: this.entryData.id,
                comment: this.comment,
                bookDate: moment(this.bookDate).format('YYYY-MM-DD'),
                taskId: taskId,
                finished: this.finished,
              },
            })
            this.updateTimer()
            this.$emit('close')
          } catch (e) {
            console.log('error', e)
            if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
              this.$refs.observerComment.setErrors(e.graphQLErrors[0].fieldErrors)
            }
          }
        }
      } else {
        this.$notify('Form is invalid. Please fix validation errors.', 'is-danger', true)
      }
    },
    async createEntry() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        let taskId
        if (this.selectedTask) {
          taskId = this.selectedTask.id
        } else {
          taskId = this.task.id
        }
        try {
          const response = await this.$apollo.mutate({
            mutation: createEntryMutation,
            variables: {
              bookDate: moment(this.bookDate).format('YYYY-MM-DD'),
              taskId: taskId,
              finished: false,
            },
          })
          this.entryData = response.data.createEntry
          this.addTimeEntry()
        } catch (e) {
          if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
            this.$refs.observer.setErrors(e.graphQLErrors[0].fieldErrors)
          }
        }
      }
    },
    async cancel() {
      if (this.entryData && this.getTotalSeconds() <= 1 && !this.edit && this.task) {
        try {
          await this.$apollo.mutate({
            mutation: deleteEntryMutation,
            variables: {
              id: this.entryData.id,
            },
          })
          this.updateTimer()
          this.$emit('close')
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .is-colon {
  margin-top: 7px !important;
}
</style>
