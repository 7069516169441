<template>
  <div>
    <!-- <transition v-if="animated" name="fade" mode="out-in" @beforeLeave="beforeLeave" @enter="enter"  @afterEnter="afterEnter"> -->
    <transition v-if="animated" name="fade">
      <div v-show="isActive" class="s-tab-item-content">
        <slot></slot>
      </div>
    </transition>
    <div v-else v-show="isActive" class="s-tab-item-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      },
      sublabel: {
        type: String,
        default: null,
      },
      sublabelsmall: {
        type: String,
        default: null,
      },
      selected: {
        type: Boolean,
        default: false
      },
      animated: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        isActive: false,
        prevHeight: 0,
        isTransitioning: this.animated,
        isVisible: true,
      }
    },
    mounted() {
      this.isActive = this.selected;
      this.isVisible = this.visible;

    },
    methods: {
      beforeLeave(element) {
        this.prevHeight = getComputedStyle(element).height;
      },
      enter(element) {
        const {height} = getComputedStyle(element);

        element.style.height = this.prevHeight;

        setTimeout(() => {
          element.style.height = height;
        });
      },
      afterEnter(element) {
        element.style.height = 'auto';
      },
    },


  }
</script>

<style lang="scss" scoped>
  .fade-enter-active {
    transition: all 0.7s;
    transition-delay: 0.2s;
  }

  /*  fade out starts immediately and transitions within 0s to properties 0 */
  .fade-leave-active {
    transition: all 0s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0;
    height: 0;
  }
</style>
