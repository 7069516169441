<template>
  <span ref="tooltip" :class="rootClasses">
    <transition :name="newAnimation">
      <div
          v-show="active && (isActive || always)"
          ref="content"
          class="tooltip-content"
          >
          <template v-if="label">{{ label }}</template>
          <template v-else-if="$slots.content">
              <slot name="content" />
          </template>
          <div class="arrow"></div>
      </div>
    </transition>
    <div
        ref="trigger"
        class="tooltip-trigger"
        @mouseenter="onHover"
        @mouseleave="close">
        <slot ref="slot" />
    </div>
  </span>
  
  
</template>

<script>
export default {
  props: {
        active: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'is-primary'
        },
        label: {
            type: String,
            default: null
        },
        delay: {
            type: Number,
            default: null
        },
        position: {
            type: String,
            default: 'is-top',
            validator(value) {
                return [
                    'is-top',
                    'is-bottom',
                    'is-left',
                    'is-right'
                ].indexOf(value) > -1
            }
        },
        triggers: {
            type: Array,
            default: () => ['hover']
        },
        always: Boolean,
        square: Boolean,
        dashed: Boolean,
        multilined: Boolean,
        size: {
            type: String,
            default: 'is-medium'
        },

        animated: {
            type: Boolean,
            default: false
        },
        animation: {
            type: String,
            default: 'fade'
        },
        autoClose: {
            type: [Array, Boolean],
            default: true
        }
    },
    data() {
        return {
            isActive: false,
            style: {},
            timer: null,
            //_bodyEl: undefined // Used to append to body
        }
    },computed: {
        rootClasses() {
            return ['s-tooltip', this.type, this.position, this.size, {
                'is-square': this.square,
                'is-always': this.always,
                'is-multiline': this.multilined,
                'is-dashed': this.dashed
            }]
        },
        newAnimation() {
            return this.animated ? this.animation : undefined
        }
    },
    
    methods: {
        onHover() {
            if (this.triggers.indexOf('hover') < 0) return;
            this.open()
        },
        open() {
            if (this.delay) {
                this.timer = setTimeout(() => {
                    this.isActive = true;
                    this.timer = null
                }, this.delay)
            } else {
                this.isActive = true
            }
        },
        close() {
            if (typeof this.autoClose === 'boolean') {
                this.isActive = !this.autoClose;
                if (this.autoClose && this.timer) clearTimeout(this.timer)
            }
        },
        
    },
    
  }

</script>

<style lang="scss" scoped>
@import "~assets/scss/imports";



.arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #E62121;
  transform: rotate(45deg); 
  z-index: -1000;
}

.s-tooltip {
    position: relative;
    display: inline-flex;
}

.is-primary .tooltip-content {
    background: #7957d5;
    color: #fff;
} 
.is-success .tooltip-content {
    background: #24A51E;
    color: #fff;
} 

.is-warning .tooltip-content {
    background:#EC7E0D;
    color: #fff;
} 
.is-danger .tooltip-content {
    background:#E62121;
    color: #fff;
} 

.tooltip-content {
    width: auto;
    padding: .35rem .75rem;
    border-radius: 6px;
    font-size: .85rem;
    font-weight: 400;
    box-shadow: 0 1px 2px 1px rgba(0,1,0,.2);
    z-index: 38;
    white-space: nowrap;
    position: absolute;
}

.tooltip-content:before {
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 38;
}
*, :after, :before {
    box-sizing: inherit;
}

.is-top .tooltip-content {
    top: auto;
    right: auto;
    bottom: calc(100% + 5px + 2px);
    left: 50%;
    transform: translateX(-50%);
}

 .is-bottom .tooltip-content {
    top: calc(100% + 5px + 2px);
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-trigger {
    width: 100%;
}

.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-leave-active {
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

</style>
