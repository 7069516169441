<template>
  <span v-if="segment.billingModel === 'hourly'" class="hourly" is-hourly> Billable - Person Hourly Rate </span>
  <span v-else-if="segment.billingModel === 'flat-rate'" class="flat-rate" is-flatrate> Flat rate </span>
  <span v-else-if="segment.billingModel === 'not-billable'" class="non-billable" is-nonbillable> Non Billable </span>
</template>

<script>
export default {
  props: {
    segment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hourlyRate() {
      if (this.segment.hourlyRate) {
        return ` ${this.segment.hourlyRate} CHF`
      }
      return ' (User rates)'
    },
  },
}
</script>

<style scoped lang="scss">
@import '~assets/scss/imports.scss';
span {
  font-size: 13.073px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.793px;
  padding: 4px 8px;
  justify-content: flex-end;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
}
.hourly {
  border: 1px solid var(--sybill-bg-website-bg-blue, #a8d8f6);
  background: var(--sybill-logo-watermark, rgba(3, 92, 118, 0.05));
}
.flat-rate {
  border: 1px solid var(--sybill-links-link-clicked, #6700b8);
  background: rgba(151, 71, 255, 0.12);
}

.non-billable {
  border: 1px solid var(--sybill-bg-website-bg-orange, #e59a47);
  background: rgba(208, 188, 166, 0.16);
}
</style>
