<template>
  <div  class="note-mini-panel">
    <div class="content-mini-panel">
      <s-field label="Comment" :required="entry?.task?.segment?.commentsRequired" is-large-h>
          <s-input
            v-model="note"
            placeholder="What did you work on?"
            :is-optional="true"
            style="height: 112px;"
            type="textarea"></s-input>
      </s-field>
    </div>
    <div class="footer-mini-panel">
      <s-button
        tag="input"
        value="Cancel"
        @click="cancel">
        Cancel
      </s-button>
      <s-button
        is-primary
        native-type="submit"
        style="margin-left: 0"
        :is-disabled="entry?.task?.segment?.commentsRequired && !note"
        @click="finishTimer">
        Finish and save
      </s-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    showNote: {
      required: true,
      type: Boolean
    },
    entry: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      note: this.entry.comment
    }
  },
  computed: {
    timings () {
      if (this.entry) {
        return this.entry.timings
      }
      return []
    },
    getTotalSeconds () {
      return this.$store.getters['timer/totalSeconds']
    }
  },
  watch: {
    entry() {
      //this.note = this.entry.comment
    }
  },
  methods: {
    getDuration (seconds) {
      return moment.duration(seconds, 'seconds')
    },
    async finishTimer (stop = true) {
      if (this.getDuration(this.getTotalSeconds).asHours() >= 24) {
        this.$notify('There are only 24hrs in a day! ⏳', 'is-danger', true)
      } else {
        try {
          await this.$store.dispatch('timer/pauseTimer', { stop, comment: this.note, entry: this.entry })
          await this.$store.dispatch('timer/getMyEntries', this.$route.params.organisation)
          this.$bus.$emit('updateTasks');
          this.$notify('Task successfully saved', 'is-success', true);
          this.cancel()
        } catch (e) {
          console.log('error', e);
          if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
            this.$refs.observerComment.setErrors(e.graphQLErrors[0].fieldErrors)
          }
        }
      }
    },
    cancel() {
      this.$emit('update:showNote', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";
  .note-mini-panel {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 32px;
    padding: 24px;
    position: absolute;
    bottom: 100px;
    right: 0;
    width: 100%;
    background: white;
    border-top: 1px solid $border-default;

    .content-mini-panel {
      flex-shrink: 0;
    }

    .footer-mini-panel {
      display: flex;
      flex-direction: column-reverse;
      justify-content: stretch;
      gap: 16px;
    }
  }
  </style>
