<template>
  <div>
    <ul class="s-tabs" :class="{'is-centered' : center}">
      <li
        v-for="(childItem, index) in tabs"
        v-show="childItem.isVisible"
        :key="index"
        class="s-tab-item">
        <s-slot-component
          v-if="childItem.$slots.header"
          :class="{'is-active' : childItem.isActive}"
          :component="childItem"
          :label="childItem.label"
          name="header"
          tag="a"
          @click.native="selectTab(childItem, index)">
        </s-slot-component>
        <a
          v-else
          :class="{'is-active' : childItem.isActive}"
          @click="selectTab(childItem, index)">
          {{ childItem.label }}
        </a>
      </li>
    </ul>

    <section class="s-tab-content" :class="{'is-transitioning': isTransitioning}">
      <slot name="header"></slot>
      <slot></slot>
    </section>
  </div>
</template>

<script>
  import SlotComponent from './SlotComponent'

  export default {
    components: {
      [SlotComponent.name]: SlotComponent
    },
    props: {
      animated: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isTransitioning: this.animated,
        tabs: []
      }
    },
    created() {
      this.tabs = this.$children
    },
    mounted() {
      this.tabs.forEach(tab => {
        tab.animated = this.animated;
      })
    },
    methods: {
      selectTab(selectedTab, index) {
        this.tabs.forEach(tab => {
          tab.isActive = tab.label === selectedTab.label
        });
        this.$emit('selectTab', index);
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-tabs {
    border-top: $border-thin solid $ash;
    border-bottom: $border-thin solid $ash;
    list-style: none;
    display: flex;
    margin: 0;
    align-content: center;

    &.is-centered {
      justify-content: center;
    }

    .s-tab-item {
      display: flex;
      align-content: center;

      a {
        border-top: $border-thick solid transparent;
        margin-top: -$border-thin;
        align-self: center;
        height: 100%;
        padding: 10px 15px 15px 15px;

        &:hover {
          text-decoration: none;
          color: $link-hover;
        }

        &.is-active {
          border-color: $emerald;
        }
      }
    }
  }

  .header-tabs {
    .s-tabs {
      background: $white;
      padding-left: $content-container-padding-sides;
      padding-right: $content-container-padding-sides;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }

  .timesheets-tabs {
    .s-tabs {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 1px;
      background: $ash;
      border-bottom: none; //necessary when .card is applied on parent

      .s-tab-item {

        a {
          background-color: $smoke;
          width: 100%;
          text-align: center;
          border-bottom: $border-thin solid $ash;
          padding-left: 5px;
          padding-right: 5px;

          &.is-active {
            background-color: $white;
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
</style>
