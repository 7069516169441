<template>
  <modal-wrapper>
    <s-loading
      :is-full-page="false"
      :active="loading"
      :can-cancel="false"
    />
    <template v-if="!loading">
      <s-card-header has-cross @close="$emit('close')">
        <h1>{{ projects?.length ? "Select a project to track your time" : "New project" }}</h1>
      </s-card-header>
      <project-list-track-time
        v-if="projects.length"
        :projects="projects"
        @update="$apollo.queries.projects.refetch()"
        @close="$emit('close')"
      />
      <project-form
        v-else
        @save="onSave"
        @cancel="$emit('close')"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import projectsQuery from "~/graphql/queries/projects.graphql"
import ProjectForm from '~/components/ProjectForm.vue'
import ProjectListTrackTime from '~/components/ProjectListTrackTime.vue'

export default {
  name: "TrackTimeModal",
  components: {
    ProjectListTrackTime,
    ProjectForm
  },
  apollo: {
    projects: {
      query: projectsQuery,
      variables() {
        return {
          organisation: this.$route.params.organisation
        }
      }
    }
  },
  computed: {
    loading() {
      return this.$apollo.queries.projects.loading
    }
  },
  methods: {
    onSave(response) {
      this.$emit("close")
      this.$router.push({
        name: "organisation-projects-project",
        params: {
          organisation: this.$route.params.organisation,
          project: response?.data?.createProject?.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
