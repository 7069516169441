var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type !== 'link')?_c('button',_vm._g({staticClass:"timer-btn",class:{
    'pause':_vm.isPause,
    'play':_vm.isPlay,
    'edit':_vm.isEdit,
    'stop':_vm.isStop,
    'is-small':_vm.isSmall,
  },attrs:{"type":_vm.type}},_vm.$listeners)):_c('button',_vm._g({staticClass:"link-play-left",class:{
    'btn-link btn-link-play-left':_vm.isPlayLink,
    'btn-link btn-link-pause-left':_vm.isPauseLink,
  }},_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }