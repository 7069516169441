<template>
  <div
    class="s-field"
    :class="{
      'has-error':hasError,
      'is-optional':isOptional,
      'is-small-v':isSmallV,
      'is-small-h':isSmallH,
      'is-medium-h':isMediumH,
      'is-large-h':isLargeH,
      'is-horizontal':isHorizontal,
      }"
    :width=width>
    <label
      v-if="label">
      {{ label }}
      <span v-if="required" class="star">
        *
      </span>
    </label>
    <div v-if="isFormGroup" class="form-group-wrapper">
      <slot></slot>
    </div>
    <slot v-else></slot>
    <small
      v-if="errorMessage" class="is-danger">
      {{ errorMessage }}
    </small>
    <p
      v-if="newMessage && !isHorizontal"
      class="help"
      :class="newType"
    >
      <slot v-if="$slots.message" name="message"/>
      <template v-else>
          <template v-for="(mess, i) in formattedMessage">
              {{ mess }}
              <br v-if="(i + 1) < formattedMessage.length" :key="i">
          </template>
      </template>
    </p>
    <small
      v-if="help" class="text-muted">
      {{ help }}
    </small>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: [String, Object],
        default: null
      },
      label: {
        type: String,
        default: null,
      },
      help: {
        type: String,
        default: null,
      },
      message: { 
        type: [String, Array, Object],
        default: null
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: null,
      },
      isOptional: {
        type: Boolean,
        default: false,
      },
      isSmallV: {
        type: Boolean,
        default: false,
      },
      isSmallH: {
        type: Boolean,
        default: false,
      },
      isMediumH: {
        type: Boolean,
        default: false,
      },
      isLargeH: {
        type: Boolean,
        default: false,
      },
      isFormGroup: {
        type: Boolean,
        default: false,
      },
      isHorizontal: {
        type: Boolean,
        default: false,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      required: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return{
        newType: this.type,
        newMessage: this.message
      }
    },
    computed: {
      formattedMessage() {
        if (typeof this.newMessage === 'string') {
            return [this.newMessage]
        }
        let messages = []
        if (Array.isArray(this.newMessage)) {
            this.newMessage.forEach((message) => {
                if (typeof message === 'string') {
                    messages.push(message)
                } else {
                    for (let key in message) {
                        if (message[key]) {
                            messages.push(key)
                        }
                    }
                }
            })
        } else {
            for (let key in this.newMessage) {
                if (this.newMessage[key]) {
                    messages.push(key)
                }
            }
        }
        return messages.filter((m) => { if (m) return m })
      },
    },
    watch: {
      message(value) {
          this.newMessage = value
      },
      type(value) {
          this.newType = value
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports.scss";
  .s-field {
    &.is-large-h {
      label {
        font-size: rem(18px);
      }
      small {
        font-size: rem(18px);
      }
    }
  }
</style>
