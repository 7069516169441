<template>
  <header class="card-header">
    <slot></slot>
    <i
      v-if="hasCross"
      class="s-cross"
      @click="$emit('close')"
    />
  </header>
</template>

<script>
  export default {
    props: {
      hasCross: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .card-header {
    padding-bottom: 24px;
    border-bottom: $border-thin solid $ash;
    box-shadow: none;
    display: flex;
    justify-content: space-between;

    .inner {
      display: flex;
      align-items: center;
    }

    .s-cross {
      margin-right: 19px;
      cursor: pointer;
    }
  }
</style>
