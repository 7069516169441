<template>
  <div class="main">
    <nuxt />
  </div>
</template>

<script>
export default {
  mounted() {},
}
</script>
