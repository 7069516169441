<template>
  <button
    v-if="type !== 'link'"
    class="timer-btn"
    :type="type"
    :class="{
      'pause':isPause,
      'play':isPlay,
      'edit':isEdit,
      'stop':isStop,
      'is-small':isSmall,
    }"
    v-on="$listeners">
  </button>
  <button
    v-else
    class="link-play-left"
    :class="{
      'btn-link btn-link-play-left':isPlayLink,
      'btn-link btn-link-pause-left':isPauseLink,
    }"
    v-on="$listeners">
    <slot></slot>
  </button>
</template>
<style lang="scss" scoped>
  @import "~assets/scss/imports";

  $timer-btn-size: 40px;

  .timer-btn {
    border-radius: 50%;
    border: none;
    cursor: pointer;

    + .timer-btn {
      margin-left: 10px;
    }

    &.pause {
      @include icon-standalone-button($icon: $icomoon-pause, $size: rem(20px), $width: 40px, $height: 40px);
      background: $primary;
      color: $white;
      animation: timer-pulse 1s infinite;

      &:hover {
        background: $primary-hover;
      }

      &.is-small {
        @include icon-standalone-button($icon: $icomoon-pause, $size: 2rem, $width: 10px, $height: 10px);
      }
    }

    &.play {
      @include icon-standalone-button($icon: $icomoon-play, $size: rem(20px), $width: 40px, $height: 40px);
      background: $primary;
      color: $white;

      &:hover {
        background: $primary-hover;
      }

      &.is-small {
        @include icon-standalone-button($icon: $icomoon-play, $size: 2rem, $width: 10px, $height: 10px);
      }
    }

    &.edit {
      @include icon-standalone-button($icon: $icomoon-edit, $size: 1.5rem, $width: 40px, $height: 40px);
      border: 1px solid $charcoal;
      color: $charcoal;
      background: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &.stop {
      @include icon-standalone-button($icon: $icomoon-save, $size: 1.5rem, $width: 40px, $height: 40px);
      background: $emerald;
      color: $white;

      &:hover {
        background: darken($emerald, 10%);
      }
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .btn-link-play-left,
  .btn-link-pause-left {
    color: $text-color;

    &:hover {
      color: $text-color;
      text-decoration: underline;
    }

    &:before {
      border-radius: 50%;
      background: white;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn-link-play-left {
    @include icon-left($padding: 1.7em, $icon: $icomoon-play-circle, $icon-size: 1.4em);

    &:before {
      color: $timer-orange;
    }

    &:disabled,
    &[disabled] {
      &:hover {
        text-decoration: none;
        color: $text-color;
      }
    }
  }

  .btn-link-pause-left {
    @include icon-left($padding: 1.7em, $icon: $icomoon-pause-circle, $icon-size: 1.4em);

    &:before {
      color: $timer-green;
      animation: timer-pulse 1s infinite;
    }
  }

</style>
<script>
  export default {
    props: {
      isPause: {
        type: Boolean,
        default: false,
      },
      isPlay: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      isStop: {
        type: Boolean,
        default: false,
      },
      isSmall: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: null,
      },
      isPlayLink: {
        type: Boolean,
        default: false,
      },
      isPauseLink: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
