<template>
  <div>
    <span v-if="progress" class="tag is-primary">
      In progress
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      progress: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
