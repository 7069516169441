<template>
  <div v-if="allTimers" class="sticky-timer">
    <div class="inner">
      <timer
        v-if="runningTimer"
        class="running-timer"
        :task="runningTimer.task"
        show-details
      />
      <timer
        v-else-if="firstPausedTimer"
        class="paused-timer"
        :task="firstPausedTimer.task"
        show-details
      />
    </div>
  </div>
</template>

<script>
  import timer from './Timer'
  import profileQuery from "~/graphql/queries/profile.graphql"

  export default {
    components: {
      timer,
    },
    apollo: {
      profile: {
        query: profileQuery,
      }
    },
    computed: {
      userProfile () {
        if (this.profile) {
          return this.profile.userProfile
        }
        return {}
      },
      allTimers() {
        return this.$store.getters['timer/all']
      },
      runningTimer () {
        return this.allTimers.find(e => !e.finished && e.isRunning)
      },
      firstPausedTimer () {
        let timersLength = this.pausedTimers.length
        if (timersLength) {
          return this.pausedTimers[timersLength - 1]
        }
        return null
      },
      pausedTimers () {
        return this.allTimers.filter(e => e.myEntry && !e.finished && !e.isRunning)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .sticky-timer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: 0;
    width: calc(100vw - 240px);
    background-color: $background-dark;
    @media print {
      display: none;
    }
  }

</style>
