<template>
  <span ref="tooltip" :class="rootClasses">
    <transition :name="newAnimation">
      <div
        v-show="active && (isActive || always)"
        ref="content"
        class="tooltip-content"
        :class="{'custom-content':customContent}">
          <template
            v-if="label">
            {{ label }}
          </template>
          <template
            v-else-if="$slots.content">
            <div class="inner">
              <slot name="content"/>
            </div>
          </template>
      </div>
    </transition>
    <div
      ref="trigger"
      class="tooltip-trigger"
      @mouseenter="onHover"
      @mouseleave="close">
        <slot ref="slot"/>
    </div>
  </span>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: true
      },
      customContent: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'is-light'
      },
      label: {
        type: String,
        default: null
      },
      triggers: {
        type: Array,
        default: () => ['hover']
      },
      animated: {
        type: Boolean,
        default: true
      },
      animation: {
        type: String,
        default: 'fade'
      },
      autoClose: {
        type: [Array, Boolean],
        default: true
      },
      always: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isActive: false,
        style: {},
        timer: null, // Used to append to body
      }
    }, computed: {
      rootClasses() {
        return ['s-tooltip', this.type]
      },
      newAnimation() {
        return this.animated ? this.animation : undefined
      }
    },


    created() {
      if (typeof window !== 'undefined') {
        document.addEventListener('click', this.clickedOutside);
        document.addEventListener('keyup', this.keyPress)
      }
    },
    beforeDestroy() {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', this.clickedOutside);
        document.removeEventListener('keyup', this.keyPress)
      }
    },
    methods: {
      onClick() {
        if (this.triggers.indexOf('click') < 0) return;
        // if not active, toggle after clickOutside event
        // this fixes toggling programmatic
        this.$nextTick(() => {
          setTimeout(() => this.open())
        })
      },
      onHover() {
        if (this.triggers.indexOf('hover') < 0) return;
        this.open()
      },
      onContextMenu(e) {
        if (this.triggers.indexOf('contextmenu') < 0) return;
        e.preventDefault();
        this.open()
      },
      onFocus() {
        if (this.triggers.indexOf('focus') < 0) return;
        this.open()
      },
      open() {
        this.isActive = true
      },
      close() {
        if (typeof this.autoClose === 'boolean') {
          this.isActive = !this.autoClose;
          if (this.autoClose && this.timer) clearTimeout(this.timer)
        }
      },
      /**
       * Close tooltip if clicked outside.
       */
      clickedOutside(event) {
        if (this.isActive) {
          if (Array.isArray(this.autoClose)) {
            if (this.autoClose.indexOf('outside') >= 0) {
              if (!this.isInWhiteList(event.target)) this.isActive = false
            } else if (this.autoClose.indexOf('inside') >= 0) {
              if (this.isInWhiteList(event.target)) this.isActive = false
            }
          }
        }
      },
      /**
       * Keypress event that is bound to the document
       */
      keyPress({key}) {
        if (this.isActive && (key === 'Escape' || key === 'Esc')) {
          if (Array.isArray(this.autoClose)) {
            if (this.autoClose.indexOf('escape') >= 0) this.isActive = false
          }
        }
      },
    },
    removeElement(el) {
      if (typeof el.remove !== 'undefined') {
        el.remove()
      } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
        el.parentNode.removeChild(el)
      }
    },
    createAbsoluteElement(el) {
      const root = document.createElement('div');
      const wrapper = document.createElement('div');
      root.appendChild(wrapper);
      wrapper.appendChild(el);
      document.body.appendChild(root);
      return root
    },

  }

</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  $tooltip-arrow-size: 5px;

  .s-tooltip {
    position: relative;
    display: inline-flex;

    .tooltip-content {
      width: auto;
      padding: 0.35rem 0.5rem;
      border-radius: $border-radius;
      box-shadow: 0px 3px 10px 2px rgba(0, 1, 0, 0.2);
      z-index: 38;
      white-space: nowrap;
      position: absolute;
      top: auto;
      right: auto;
      bottom: calc(100% + #{$tooltip-arrow-size} + 2px);
      left: 50%;
      transform: translateX(-50%);

      &.custom-content {
        //white-space: normal;

        .inner {
          text-align: center;
          //min-width: 200px;
          //max-width: 300px;
        }
      }

      &:before {
        position: absolute;
        content: "";
        pointer-events: none;
        z-index: 38;
        top: 100%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        border-top: $tooltip-arrow-size solid $charcoal;
        border-right: $tooltip-arrow-size solid transparent;
        border-left: $tooltip-arrow-size solid transparent;
      }
    }

    &.is-dark {
      .tooltip-content {
        background-color: $charcoal;
        color: $white;
      }
    }

    &.is-light {
      .tooltip-content {
        background-color: $white;
        color: $text-color;

        &:before {
          border-top-color: white;
        }
      }
    }

    .tooltip-trigger {
      cursor: pointer;
    }
  }
</style>
