var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-radio",class:{
 'is-required':_vm.isOptional,
 'is-disabled':_vm.isDisabled,
 }},[(_vm.label)?_c('label',{class:{
    'is-required-label':_vm.isOptional,
    'has-error':_vm.error
  }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",attrs:{"type":"radio","name":_vm.name,"disabled":_vm.isDisabled,"required":!_vm.isOptional,"error":_vm.error},domProps:{"value":_vm.nativeValue,"checked":_vm._q(_vm.computedValue,_vm.nativeValue)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){_vm.computedValue=_vm.nativeValue}}}),_vm._v(" "),_c('span',{staticClass:"checkmark"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }