<template>
  <div class="s-progress" :class="isFlex ? 'is-flex' : ''">
    <s-number
      v-if="showValue"
      :class="{
        'is-green':isSuccess,
        'is-orange':isWarning,
        'is-red':isDanger,
        'is-large':isLarge,
        'is-medium':isMedium,
        'is-small':isSmall,
      }">
      {{ newValue }} <span v-if="showMax">/ {{ max }}</span>
    </s-number>
    <div class="progress-wrapper">
      <progress
        ref="progress"
        :max="max"
        :value="value"
        :class="{
        'is-success':isSuccess,
        'is-warning':isWarning,
        'is-danger':isDanger,
        'is-full':value >= max,
      }">
        <!--
        TODO implement fallback https://css-tricks.com/html5-progress-element/
        <div class="progress-bar-fallback">
          <span :style="width:"newValue";">{{ newValue }}</span>
        </div>
        -->
      </progress>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SProgressBar',
    props: {
      type: {
        type: String,
        default: 'is-success'
      },
      isSmall: {
        type: Boolean,
        default: false,
      },
      isLarge: {
        type: Boolean,
        default: false,
      },
      isMedium: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Number,
        default: undefined
      },
      max: {
        type: Number,
        default: 100
      },
      showValue: {
        type: Boolean,
        default: false
      },
      showMax: {
        type: Boolean,
        default: false
      },
      isSuccess: {
        type: Boolean,
        default: false,
      },
      isDanger: {
        type: Boolean,
        default: false,
      },
      isWarning: {
        type: Boolean,
        default: false,
      },
      format: {
        type: String,
        default: 'raw',
        validator: (value) => {
          return [
            'raw',
            'percent'
          ].indexOf(value) >= 0
        }
      },
      precision: {
        type: Number,
        default: 2
      },
      keepTrailingZeroes: {
        type: Boolean,
        default: false
      },
      isFlex: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isIndeterminate() {
        return this.value === undefined || this.value === null
      },
      newType() {
        return [
          this.size,
          this.type,
          {
            'is-more-than-half': this.value && this.value > this.max / 2
          }
        ]
      },
      newValue() {
        if (this.value === undefined || this.value === null || isNaN(this.value)) {
          return undefined
        }
        const minimumFractionDigits = this.keepTrailingZeroes ? this.precision : 0;
        const maximumFractionDigits = this.precision;
        if (this.format === 'percent') {
          return new Intl.NumberFormat(
              this.locale,
              {
                style: 'percent',
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: maximumFractionDigits
              }
          ).format(this.value / this.max)
        }
        return new Intl.NumberFormat(
            this.locale,
            {
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: maximumFractionDigits
            }
        ).format(this.value)
      }
    },
    watch: {
      /**
       * When value is changed back to undefined, value of native progress get reset to 0.
       * Need to add and remove the value attribute to have the indeterminate or not.
       */
      isIndeterminate(indeterminate) {
        this.$nextTick(() => {
          if (this.$refs.progress) {
            if (indeterminate) {
              this.$refs.progress.removeAttribute('value')
            } else {
              this.$refs.progress.setAttribute('value', this.value)
            }
          }
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import "~assets/scss/imports";

  $progress-border-radius: 10px;

  .s-progress {

    &.is-flex {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .progress-wrapper {
      width: 100%;
      border: 1px solid $ash;
      border-radius: $progress-border-radius;
    }

    progress[value] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: white;
      display: block;
      border: none;
      width: 100%;
      min-width: 28px;
      height: 5px;

      &::-webkit-progress-bar {
        background-color: white;
        border-radius: $progress-border-radius;
      }

      &::-webkit-progress-value {
        background-color: $emerald;
        border-bottom-left-radius: $progress-border-radius;
        border-top-left-radius: $progress-border-radius;
      }

      &::-moz-progress-bar {
        background-color: $emerald;
      }

      &.is-danger {
        &::-webkit-progress-value {
          background-color: $red;
        }

        &::-moz-progress-bar {
          background-color: $red;
        }
      }

      &.is-success {
        &::-webkit-progress-value {
          background-color: $green;
        }

        &::-moz-progress-bar {
          background-color: $green;
        }
      }

      &.is-warning {
        &::-webkit-progress-value {
          background-color: $orange;
        }

        &::-moz-progress-bar {
          background-color: $orange;
        }
      }

      &.is-full {
        &::-webkit-progress-value {
          border-top-right-radius: $progress-border-radius;
          border-bottom-right-radius: $progress-border-radius;
        }
      }
    }
  }
</style>
