<template>
  <div :isClosable="isClosable" class="s-notification">
    <div style="width: 100%; display: flex; justify-content: space-between">
      <slot></slot>
      <button v-if="isClosable" class="close-btn" @click="$emit('remove')"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isClosable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.isClosable) {
      this.$emit('remove-after-delay')
    }
  },
  methods: {
    closeNotification(index) {
      console.log('clicked')
      this.list.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/imports.scss';

.s-notification {
  border-top: 8px solid;
  padding: 24px 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  position: relative;
  z-index: 999;

  .close-btn {
    @include icon-standalone-button($icomoon-cross, 1.3rem, 38px, 22px);
    margin-left: 10px;
    color: $charcoal;
    border-radius: 10rem;
    border: none;
    cursor: pointer;
    background: transparent;

    &:active,
    &:active:focus,
    &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: underline;
  }

  &.is-info {
    border-color: $alert-info;
    color: $emerald;

    strong {
      color: $emerald;
    }
  }

  &.is-danger {
    border-color: $alert-danger;
    color: #a50114;

    strong,
    a {
      color: $danger;
    }

    a:hover {
      color: lighten($danger, 10%);
    }
  }

  &.is-success {
    border-color: $alert-success;
    color: #005d1c;

    strong,
    a {
      color: $green;
    }

    a:hover {
      color: lighten($green, 10%);
    }
  }

  &.is-warning {
    border-color: $alert-warning;
    color: #b64c00;

    strong,
    a {
      color: $orange;
    }

    a:hover {
      color: lighten($orange, 10%);
    }
  }
}
</style>
