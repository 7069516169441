<template>
  <div class="organisation">
    <img
      v-if="organisationLogo"
      :src="organisationLogo"
      alt="Organisation Logo"
    >
    <Rectangle v-else class="rectangle"/>
    <div class="profile">
      <img
        v-if="userProfilePicture"
        :src="userProfilePicture"
        alt="Profile Picture"
      >
      <AvatarIcon
        v-else
        class="avatar"
      />
    </div>
  </div>
</template>

<script>
import Rectangle from '~/components/utils/Rectangle.vue'
import AvatarIcon from '~/components/utils/AvatarIcon.vue'

export default {
  name: "OrganisationProfileLogo",
  components: { AvatarIcon, Rectangle },
  props: {
    organisationLogo: {
      type: String,
      default: ""
    },
    userProfilePicture: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped lang="scss">
.organisation {
  width: 32px;
  height: 32px;
  position: relative;

  .rectangle {
    width: 32px;
    height: 32px;
  }

  .profile {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .avatar {
      width: 20px;
      height: 20px;
    }
  }
}

</style>
