<template>
  <div class="s-dropdown" :class="{'disabled': isDisabled}">
    <button class="s-dropdown_btn" :disabled="isDisabled" @click="toggleDropdown">
      <p class="s-dropdown_btn-text">{{text}}</p>
      <span class="icomoon-chevron-down icon" :class="{'is-open': isOpen}"/>
    </button>
    <div
      v-if="isOpen"
      class="dropdown-outside"
      @click.stop="toggleDropdown"
    ></div>
    <div v-if="isOpen" class="s-dropdown_options">
      <div
        v-for="option in options"
        :key="option"
        class="s-dropdown_option"
        @click="selectOption(option)"
      >
        {{option}}
        <span v-if="value === option" class="icomoon-thin-check check"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Drop down'
    },
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => ([])
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleDropdown() {
      if (this.isDisabled) return;
      this.isOpen = !this.isOpen
      this.$emit('toggle')
    },
    selectOption(option) {
      this.$emit('input', option);
      this.isOpen = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/imports.scss";

.s-dropdown {
  position: relative;

  &.disabled .s-dropdown_btn {
    cursor: not-allowed !important;
  }

  .s-dropdown_btn {
    all: unset;
    display: flex;
    align-items: center;
    gap: rem(12px);
    cursor: pointer;
    font-weight: 700;
    font-size: rem(18px);
    color: $grey-extra-1;

    .s-dropdown_btn-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon {
      font-weight: 400;
      font-size: 20px;
      position: relative;
      top: rem(2px);
      color: $grey-extra-4;

      &.is-open {
        transform: rotate(180deg);
      }
    }
  }

  .s-dropdown_options {
    background: $white;
    position: absolute;
    margin-top: rem(12px);
    box-shadow: 0px 11px 31px 0px #00000029;
    max-width: fit-content;
    min-width: 200px;

    .s-dropdown_option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(16px);
      cursor: pointer;
      font-size: rem(18px);
      font-width: 500;
      color: $grey-extra-1;
      border-bottom: 1px solid $border-soft-color;

      &:hover {
        background: $background-light-hover;
      }

      .check {
        color: $alert-success;
      }
    }
  }

  .dropdown-outside {
    position: fixed;
    inset: 0;
  }
}
</style>
