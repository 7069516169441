<template>
  <modal-wrapper class="add-project-modal-footer">
    <header class="modal-card-head modal__header">
      <h1>{{ title }}</h1>
    </header>
    <ProjectForm
      :customer="customer"
      :project="project"
      @archived="onArchive"
      @save="$emit('close')"
      @cancel="$emit('close')"
    />
  </modal-wrapper>
</template>

<script>
import ProjectForm from '~/components/ProjectForm'

export default {
  components: {
    ProjectForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      default: null,
      required: false,
    },
    customer: {
      type: Object,
      default: null,
      required: false,
    },
  },
  methods: {
    onArchive() {
      this.$parent.close()
      this.$emit('archived')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/imports.scss';

.modal__header {
  padding-bottom: 24px;
  border-bottom: 1px solid $border-default;
  h1 {
    color: $grey-extra-1;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }
}
</style>
