
<template>
  <div v-if="simple">
    <div v-if="entry" class="timer actions">
      <div v-if="entry.isRunning" class="is-flex">
        <s-tooltip
          label="Running timer"
          animated
          type="is-dark">
          <s-icon-button
            is-pause
            :is-disabled="isDisabled"
            @click.stop="pauseTimer()">
          </s-icon-button>
        </s-tooltip>
      </div>
      <div v-else class="is-flex">
        <s-tooltip
          label="Paused timer"
          animated
          type="is-dark">
          <s-icon-button
            is-play
            :is-disabled="isDisabled"
            @click.stop="startTimer(task)">
          </s-icon-button>
        </s-tooltip>
      </div>
    </div>
    <div v-else class="timer actions">
      <!--//TODO refactor buttons to avoid repeat-->
      <s-tooltip
        label="Start timer"
        animated
        type="is-dark">
        <s-icon-button
          is-play
          is-grey
          :is-disabled="isDisabled"
          @click.stop="startTimer(task)">
        </s-icon-button>
      </s-tooltip>
    </div>
  </div>
  <div v-else>
    <div v-if="entry" class="timer">
      <div class="sticky-timer-flex">
        <div class="timer-controls">
          <div v-if="showDetails" class="task">
            <p class="task-title">{{ entry.task.name }}</p>
            <p class="project-name">{{ entry.project.name }}</p>
          </div>
          <div>
            <timer-button
              v-if="entry.isRunning"
              class="timer-button"
              is-pause
              @click.stop="pauseTimer()">
            </timer-button>
            <timer-button
              v-else
              class="timer-button"
              is-play
              @click.stop="startTimer(entry.task)">
            </timer-button>
            <s-number
              v-if="entry.isRunning"
              class="timer-counter"
              is-xl>
              {{ currentTotalSeconds | formatActiveTimer }}
            </s-number>
            <s-number v-else is-xl class="timer-counter" :class="{'un-started': !getTotalSeconds(entry.id)}">
              {{ getTotalSeconds(entry.id) | formatActiveTimer }}
            </s-number>
          </div>
          <div class="timer-icons">
            <div class="timer-icon" @click.stop="editEntry2({ edit: true })">
              <i class="timer-icon-edit"></i>
            </div>
            <div class="timer-icon" @click.stop="showNote=!showNote">
              <i class="timer-icon-save"></i>
            </div>
            <div v-if="!hideMaximize" class="timer-icon" @click="showMyTimers">
              <i class="timer-icon-maximize"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="timer">
      <s-tooltip
        label="Start timer"
        animated
        type="is-dark">
        <s-icon-button
          is-play
          is-grey
          @click.stop="startTimer(task)">
        </s-icon-button>
      </s-tooltip>
    </div>
    <AddNote v-if="showNote" :show-note.sync="showNote" :entry="entry" style="z-index: 50"></AddNote>
  </div>
</template>

<script>
import moment from 'moment';
import AddNote from "~/components/dashboard/AddNote"
import EntryModal from "~/components/modals/EntryModal"
import SIconButton from "~/components/utils/SIconButton";
import MyTimersPanel from '~/components/panels/MyTimersPanel.vue'

export default {
  components: {
    SIconButton,
    AddNote
  },
  filters: {
    formatActiveTimer (value) {
      if (value == null) return '';
      const m = moment.duration(value, 'seconds');
      if (m.asMilliseconds()) {
        return m.format('H[h] m[m] s[s]', { minValue: 1, trim: false, trunc: true })
      }
      return m.format('H[h] m[m] s[s]', { trim: false })
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    sticky: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'is-bottom-left'
    },
    hideMaximize: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      comment: '',
      showNote: false
    }
  },
  computed: {
    entry () {
      return this.$store.getters['timer/getMyEntryByTaskId'](this.task.id)
    },
    currentTotalSeconds () {
      return this.$store.getters['timer/currentTotalSeconds']
    }
  },
  methods: {
    getTotalSeconds(entryId) {
      return this.$store.getters['timer/totalSeconds'](entryId)
    },
    async startTimer (task) {
      await this.$store.dispatch('timer/startTimer', task.id)
    },
    async pauseTimer (stop = false) {
      await this.$store.dispatch('timer/pauseTimer', { stop, comment: this.comment })
      if (stop) {
        this.comment = ''
      }
    },
    async editEntry2 (props) {
      this.$buefy.modal.open({
        parent: this,
        canCancel: ['escape', 'outside'],
        component: EntryModal,
        width: 650,
        events: {
          close: () => {
            this.$bus.$emit('updateTasks')
          }
        },
        props: {
          title: 'Edit timer',
          ...props,
          entry: this.entry,
          commentRequired: this.entry?.task?.segment?.commentsRequired
        }
      })
    },
    showMyTimers () {
      this.$showPanel({
        component: MyTimersPanel,
        openOn: 'right',
        cssClass: 'sybill-slideout',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/imports";

.link-play-large,
.link-pause-large,
.link-stop-large,
.link-edit-large {
  border-radius: 50%;
}

.link-play-large {
  @include icon-standalone-button($icon: $icomoon-play, $size: 1rem, $width: 45px, $height: 45px);
  background: $timer-orange;
  color: $white;

  &:hover {
    background: darken($timer-orange, 10%);
  }
}

.link-pause-large {
  @include icon-standalone-button($icon: $icomoon-pause, $size: 1rem, $width: 45px, $height: 45px);
  background: $timer-green;
  color: $white;

  &:hover {
    background: darken($timer-green, 10%);
  }
}

.link-stop-large {
  @include icon-standalone-button($icon: $icomoon-stop, $size: 1rem, $width: 45px, $height: 45px);
  margin-left: 10px;
  background: $emerald;
  color: $white;

  &:hover {
    background: darken($emerald, 10%);
  }
}

.link-edit-large {
  @include icon-standalone-button($icon: $icomoon-edit, $size: 1rem, $width: 45px, $height: 45px);
  margin-left: 10px;
  border: 1px solid $charcoal;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.link-pause {
  color: $timer-green;

  &:hover {
    &:before {
      color: darken($timer-green, 10%);
    }
  }
}

.link-play.orange {
  &:before {
    color: $timer-orange;
  }

  &:hover {
    &:before {
      color: darken($timer-orange, 10%);
    }
  }
}

.link-pause:before,
.link-pause-large {
  animation: pulse 1s infinite;
  transition: color 100ms;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(122, 204, 51, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.timer-icon-save {
  @include icon-standalone($icon: $icomoon-save, $size: rem(24px));
}
.timer-icon-edit {
  @include icon-standalone($icon: $icomoon-edit-doc, $size: rem(24px));
}
.timer-icon-maximize {
  @include icon-standalone($icon: $icomoon-maximize, $size: rem(24px));
}

.timer-counter {
  vertical-align: middle;
  display: inline-block;
  font-weight: 500;
  font-size: 32px;
  color: $primary;
  padding: 0 rem(10px) 0 rem(17px);
  &.un-started {
    color: $grey-extra-7;
  }
}

.timer-button {
  vertical-align: middle;
  display: inline-block;
}

.timer-icons {
  display: flex;
  padding-top: rem(10px);
  gap: rem(29px);
}

.timer-icon {
  display: inline;
  cursor: pointer;
}

.sticky-timer-flex {
  border-top: 1px solid $border-default;
  z-index: 1;
  position: relative;
}

.task-title {
  font-size: 20px;
  font-weight: 500;
  color: $grey-extra-1;
}

.project-name {
  font-size: 16px;
  font-weight: 500;
  color: $grey-extra-3;
}
</style>
