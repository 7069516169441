export default (error, context) => {
  // clear the token from the cookies and redirect to login page if token causes an error
  if (error.message.includes('token')) {
    console.log('### token had some issues, redirecting to login page', error.message)
    context.$apolloHelpers.onLogout();
    context.redirect('/login')
    return
  }
  context.error({statusCode: 500, message: error.message, error: error,})
}
