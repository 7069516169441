import { render, staticRenderFns } from "./no-sidebar.vue?vue&type=template&id=39e9a30b&scoped=true&"
import script from "./no-sidebar.vue?vue&type=script&lang=js&"
export * from "./no-sidebar.vue?vue&type=script&lang=js&"
import style0 from "./no-sidebar.vue?vue&type=style&index=0&id=39e9a30b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e9a30b",
  null
  
)

export default component.exports