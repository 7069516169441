<template>
  <s-field class="alt-form-style s-timepicker">
    <input :placeholder="placeholder">
  </s-field>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: null,
    }
  }
}
</script>
