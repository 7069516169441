<template>
  <div v-if="filteredProjects">
    <s-card-content style="text-align: center">
      <s-field class="search">
        <s-input
          ref="searchField"
          v-model="searchPhrase"
          placeholder="Search projects"
          icon="search-normal"
          @input="filterProjects"
        >
        </s-input>
      </s-field>
      <s-table>
        <s-table-header>
          <s-table-cell>Name</s-table-cell>
          <s-table-cell>Client</s-table-cell>
          <s-table-cell>Total tracked time</s-table-cell>
          <s-table-cell>Total unbooked hours</s-table-cell>
        </s-table-header>
        <div class="table-rows">
          <s-table-row v-for="project in filteredProjects" :key="project.id">
            <s-table-cell>
              <nuxt-link
                :to="`/${$route.params.organisation}/projects/${project.id}`"
                class="project-name"
                @click.native="$emit('close')"
                v-text="project.name"
              />
            </s-table-cell>
            <s-table-cell v-text="project.customer.name"></s-table-cell>
            <s-table-cell>{{ project.totalSeconds | formatDuration }}</s-table-cell>
            <s-table-cell>{{ project.unbookedSeconds | formatDuration }}</s-table-cell>
          </s-table-row>
        </div>
      </s-table>
      <p v-if="!filteredProjects.length" style="padding-top: 10px">No project is found</p>
    </s-card-content>
    <s-card-footer class="card-footer">
      <button class="add-project-btn" @click="addProject">
        <span class="icomoon-plus-square" style="font-size: 29px;"></span>
        Add a new project
      </button>
    </s-card-footer>
  </div>
</template>

<script>
  import SCardContent from '~/components/utils/SCardContent.vue'
  import ProjectFormModal from '~/components/modals/ProjectFormModal.vue'
  import _ from 'lodash'

  export default {
    components: { SCardContent },
    props: {
      projects: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        searchPhrase: "",
        selectedProject: null,
        filteredProjects: []
      }
    },
    watch: {
      projects() {
        this.filteredProjects = this.projects
      }
    },
    mounted() {
      this.filteredProjects = this.projects
      this.$nextTick(() => {
        this.$refs.searchField.$el.children[0].focus();
      })
    },
    methods: {
      addProject() {
        this.$buefy.modal.open({
          parent: this,
          canCancel: ['escape', 'outside'],
          component: ProjectFormModal,
          props: {
            title: 'Create project',
          },
          events: {
            close: () => {
              this.$emit('update');
            }
          }

        })
      },
      filterProjects: _.debounce(function (search) {
        this.filteredProjects = this.projects.filter((project) => {
          return (
            project.name.toLowerCase().includes(search.toLowerCase()) ||
            project.customer.name.toLowerCase().includes(search.toLowerCase()))
        })
      }, 500)
    }
  }
</script>

<style lang="scss" scoped>
  @import "~assets/scss/imports.scss";

  .row, .header {
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    display: grid;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .project-name {
    font-weight: 600;
    color: $emerald;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    text-align: left;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .project-name.selected {
    color: $emerald;
    border-bottom: 1px solid $emerald;
  }

  .table-rows {
    max-height: 45vh;
    overflow-y: auto;
  }

  .search {
    padding-bottom: rem(24px);
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  .add-project-btn {
    all: unset;
    cursor: pointer;
    padding: 8px 0;
    display: flex !important;
    align-items: center;
    gap: 10px;
    color: $primary;
    font-size: 20px;
    font-weight: 500;
  }
</style>
