import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _151053bb = () => interopDefault(import('../pages/check-your-inbox.vue' /* webpackChunkName: "pages/check-your-inbox" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _eb468be8 = () => interopDefault(import('../pages/join-to-workspace.vue' /* webpackChunkName: "pages/join-to-workspace" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _28068da8 = () => interopDefault(import('../pages/mobile-not-ready.vue' /* webpackChunkName: "pages/mobile-not-ready" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _f19fa6d0 = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _29ece063 = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _3a726316 = () => interopDefault(import('../pages/email/reset-password.vue' /* webpackChunkName: "pages/email/reset-password" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06497a02 = () => interopDefault(import('../pages/_organisation/index.vue' /* webpackChunkName: "pages/_organisation/index" */))
const _9655e68e = () => interopDefault(import('../pages/_organisation/analytics/index.vue' /* webpackChunkName: "pages/_organisation/analytics/index" */))
const _726176c8 = () => interopDefault(import('../pages/_organisation/customers/index.vue' /* webpackChunkName: "pages/_organisation/customers/index" */))
const _a44a78d0 = () => interopDefault(import('../pages/_organisation/me.vue' /* webpackChunkName: "pages/_organisation/me" */))
const _0e008104 = () => interopDefault(import('../pages/_organisation/my-time.vue' /* webpackChunkName: "pages/_organisation/my-time" */))
const _b35c3b06 = () => interopDefault(import('../pages/_organisation/projects/index.vue' /* webpackChunkName: "pages/_organisation/projects/index" */))
const _28e5c6e6 = () => interopDefault(import('../pages/_organisation/settings/index.vue' /* webpackChunkName: "pages/_organisation/settings/index" */))
const _162fd9c0 = () => interopDefault(import('../pages/_organisation/analytics/members.vue' /* webpackChunkName: "pages/_organisation/analytics/members" */))
const _65d2853a = () => interopDefault(import('../pages/_organisation/analytics/projects.vue' /* webpackChunkName: "pages/_organisation/analytics/projects" */))
const _ac5b0608 = () => interopDefault(import('../pages/_organisation/settings/general.vue' /* webpackChunkName: "pages/_organisation/settings/general" */))
const _420f0cc1 = () => interopDefault(import('../pages/_organisation/settings/invoice.vue' /* webpackChunkName: "pages/_organisation/settings/invoice" */))
const _cc78a006 = () => interopDefault(import('../pages/_organisation/settings/plan-and-billing.vue' /* webpackChunkName: "pages/_organisation/settings/plan-and-billing" */))
const _6e1a3f51 = () => interopDefault(import('../pages/_organisation/settings/roles.vue' /* webpackChunkName: "pages/_organisation/settings/roles" */))
const _f8eca032 = () => interopDefault(import('../pages/_organisation/team/members.vue' /* webpackChunkName: "pages/_organisation/team/members" */))
const _24cc3214 = () => interopDefault(import('../pages/_organisation/customers/_customer/index.vue' /* webpackChunkName: "pages/_organisation/customers/_customer/index" */))
const _5813147c = () => interopDefault(import('../pages/_organisation/projects/_project/index.vue' /* webpackChunkName: "pages/_organisation/projects/_project/index" */))
const _240046d6 = () => interopDefault(import('../pages/_organisation/projects/_project/generate-invoice.vue' /* webpackChunkName: "pages/_organisation/projects/_project/generate-invoice" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-your-inbox",
    component: _151053bb,
    name: "check-your-inbox"
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/join-to-workspace",
    component: _eb468be8,
    name: "join-to-workspace"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/mobile-not-ready",
    component: _28068da8,
    name: "mobile-not-ready"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/sign-up",
    component: _0406ba31,
    name: "sign-up"
  }, {
    path: "/styleguide",
    component: _f19fa6d0,
    name: "styleguide"
  }, {
    path: "/verify",
    component: _29ece063,
    name: "verify"
  }, {
    path: "/email/reset-password",
    component: _3a726316,
    name: "email-reset-password"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:organisation",
    component: _06497a02,
    name: "organisation"
  }, {
    path: "/:organisation/analytics",
    component: _9655e68e,
    name: "organisation-analytics"
  }, {
    path: "/:organisation/customers",
    component: _726176c8,
    name: "organisation-customers"
  }, {
    path: "/:organisation/me",
    component: _a44a78d0,
    name: "organisation-me"
  }, {
    path: "/:organisation/my-time",
    component: _0e008104,
    name: "organisation-my-time"
  }, {
    path: "/:organisation/projects",
    component: _b35c3b06,
    name: "organisation-projects"
  }, {
    path: "/:organisation/settings",
    component: _28e5c6e6,
    name: "organisation-settings"
  }, {
    path: "/:organisation/analytics/members",
    component: _162fd9c0,
    name: "organisation-analytics-members"
  }, {
    path: "/:organisation/analytics/projects",
    component: _65d2853a,
    name: "organisation-analytics-projects"
  }, {
    path: "/:organisation/settings/general",
    component: _ac5b0608,
    name: "organisation-settings-general"
  }, {
    path: "/:organisation/settings/invoice",
    component: _420f0cc1,
    name: "organisation-settings-invoice"
  }, {
    path: "/:organisation/settings/plan-and-billing",
    component: _cc78a006,
    name: "organisation-settings-plan-and-billing"
  }, {
    path: "/:organisation/settings/roles",
    component: _6e1a3f51,
    name: "organisation-settings-roles"
  }, {
    path: "/:organisation/team/members",
    component: _f8eca032,
    name: "organisation-team-members"
  }, {
    path: "/:organisation/customers/:customer",
    component: _24cc3214,
    name: "organisation-customers-customer"
  }, {
    path: "/:organisation/projects/:project",
    component: _5813147c,
    name: "organisation-projects-project"
  }, {
    path: "/:organisation/projects/:project/generate-invoice",
    component: _240046d6,
    name: "organisation-projects-project-generate-invoice"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
