var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-checkbox",class:{
    'has-error':_vm.hasError,
    'is-disabled':_vm.isDisabled,
}},[(_vm.label)?_c('label',{class:{
      'has-error':_vm.hasError,
      'is-optional':_vm.isOptional
      }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n    "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled,"isOptional":_vm.isOptional,"has-error":_vm.hasError},domProps:{"value":_vm.label,"checked":Array.isArray(_vm.computedValue)?_vm._i(_vm.computedValue,_vm.label)>-1:(_vm.computedValue)},on:{"change":function($event){var $$a=_vm.computedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.label,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedValue=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"checkmark",class:_vm.isPartial ? 'partial' : ''})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }