<template>
  <span
    class="s-tag"
    :class="{
      'is-large':isLarge,
      'hourly':isHourly,
      'flatrate':isFlatrate,
      'non-billable':isNonbillable,
      'completed':isDone,
      'draft':isDraft,
      'in-progress':isProgress,
      'pending':isPending,
    }">
    <slot></slot>
  </span>
</template>
<style lang="scss" scoped>
@import "~assets/scss/imports";

.s-tag {
  height: auto;
  padding: .15em .4em .3em .4em;
  background-color: $white;
  border-radius: .4rem;
  line-height: 1;
  display: inline-flex;
  margin: 0;

  &.is-large {
    padding: 0.1em .5em .2em .5em;
  }

  &.hourly {
    color: $khaki;
    border: 1px solid $khaki;
  }

  &.flatrate {
    color: $lilac;
    border: 1px solid $lilac;
  }

  &.non-billable {
    color: $sky;
    border: 1px solid $sky;
  }

  &.completed {
    background-color: $green;
    color: $white;
  }

  &.draft {
    color: $slate;
    border: 1px solid $slate;
  }

  &.in-progress {
    background-color: $turquoise;
    color: $white;
  }

  &.pending {
    background-color: $slate;
    color: $white;
  }
}
</style>
<script>
export default {
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    isHourly: {
      type: Boolean,
      default: false,
    },
    isFlatrate: {
      type: Boolean,
      default: false,
    },
    isNonbillable: {
      type: Boolean,
      default: false,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    isProgress: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
