<template>
  <div
    class="s-table-row"
    :class="{
        'is-total':isTotal,
      }"
    v-on="$listeners">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

  .s-table-row {
    padding: 10px $table-side-padding;
    border-bottom: $border-thin solid $border-color-default;

    &.is-total {
      background-color: darken($smoke, 3%);
    }
  }
</style>
<script>
  export default {
    props: {
      isTotal: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>
