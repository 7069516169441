var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"s-i-btn",class:{
    'has-i-trash':_vm.isDelete,
    'has-i-edit':_vm.isEdit,
    'has-i-eye':_vm.isEye,
    'has-i-add':_vm.isAdd,
    'has-i-add-time':_vm.isAddTime,
    'has-i-play':_vm.isPlay,
    'has-i-pause':_vm.isPause,
    'has-i-chevron-left':_vm.isLeft,
    'has-i-chevron-right':_vm.isRight,
    'is-grey':_vm.isGrey,
    'has-i-check':_vm.hasICheck,
    'has-i-close':_vm.hasIClose,
  },attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }