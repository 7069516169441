<template>
  <div
    class="s-radio"
    :class="{
   'is-required':isOptional,
   'is-disabled':isDisabled,
   }">
    <label
      v-if="label"
      :class="{
      'is-required-label':isOptional,
      'has-error':error
    }">
      {{ label }}
      <input
        ref="input"
        v-model="computedValue"
        type="radio"
        :name=name
        :disabled="isDisabled"
        :required="!isOptional"
        :error="error"
        :value="nativeValue"
        @click.stop>
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
  @import "~assets/scss/imports";

</style>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: null,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isOptional: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [Number, String, Array, Boolean],
        default: null,
      },
      nativeValue: {
        type: [Number, String, Array, Boolean],
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        newValue: this.value
      }
    },
    computed: {
      computedValue: {
        get() {
          return this.newValue
        },
        set(value) {
          this.newValue = value;
          this.$emit('input', value)
        }
      }
    },
    watch: {
      value(newValue) {
        this.newValue = newValue
      }
    },
    methods: {
      focus() {
        // MacOS FireFox and Safari do not focus when clicked
        this.$refs.input.focus()
      }
    }
  }
</script>
