export default function ({ redirect, app }) {
  let token;
  try {
    token = app.$apolloHelpers.getToken() || null;
  } catch (e) {
    console.log('###Error: apollo getToken', e)
    return;
  }

  // If the user is authenticated redirect to home page
  const hasToken = !!token
  if (hasToken) {
    console.log('######## token was supposed to be removed, but here is the token:', { token })
    return redirect('/');
  }
}
