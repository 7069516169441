import moment from 'moment'
import Vue from 'vue'
import { normalize, schema } from 'normalizr'
import entriesQuery from '@/graphql/queries/entries'
import startTimerMutation from '@/graphql/mutations/startTimer'
import stopTimerMutation from '@/graphql/mutations/stopTimer'
import updateEntryMutation from '~/graphql/mutations/updateEntry.graphql'
import { setTimeout, clearTimeout } from 'worker-timers';
let timeout

export const state = () => ({
  all: {},
  ids: [],
  runningId: null,
  selectedDate: null
})

export const mutations = {
  setAll: function (state, { list, ids }) {
    state.all = list
    state.ids.splice(0)
    state.ids = ids
  },
  mergeAll: (state, { list, ids }) => {
    state.all = { ...state.all, ...list }
    state.ids = [...new Set([...state.ids, ...ids])]
  },
  addOne: (state, { item }) => {
    state.all[item.id] = item
    state.ids.push(item.id)
  },
  updateOne: (state, item) => {
    Vue.set(state.all, item.id, item)
    state.ids.push(item.id)
  },
  setTotalSeconds: (state, { id, totalSeconds }) => {
    state.all[id].totalSeconds = totalSeconds
  },
  tick(state) {
    if (state.all[state.runningId]) {
      state.all[state.runningId].totalSeconds += 1
    }
  },
  setRunningId(state, id) {
    state.runningId = id
  },
  setSelectedDate (state, selectedDate) {
    state.selectedDate = selectedDate
  }
}

export const actions = {
  async getMyEntries({ commit, getters, dispatch }, organisationId) {
    let client = this.app.apolloProvider.defaultClient
    const response = await client.query({
      query: entriesQuery,
      variables: {
        organisationId: organisationId,
        onlyMyEntries: true,
        finished: false,
      },
      fetchPolicy: 'no-cache',
    })
    const entry = new schema.Entity('entries')
    const responseSchema = {
      entries: [entry],
    }
    const normalizedData = normalize(response.data, responseSchema)
    commit('setAll', {
      list: normalizedData.entities.entries || {},
      ids: normalizedData.result.entries,
    })
    const runningTimer = getters['all'].find((e) => !e.finished && e.isRunning)
    if (runningTimer) {
      // Used to set the correct total seconds of a running timer because the backend does not calculate its live
      commit('setTotalSeconds', { id: runningTimer.id, totalSeconds: getters['totalSeconds'](runningTimer.id) })
      commit('setRunningId', runningTimer.id)
      dispatch('tick')
    }
  },
  tick({ state, dispatch, commit }, id) {
    if (state.runningId) {
      if (timeout) {
        clearTimeout(timeout)
      }
      commit('tick', id)
      timeout = setTimeout(() => dispatch('tick'), 1000)
    }
  },
  async startTimer({ commit, dispatch, getters }, taskId) {
    let client = this.app.apolloProvider.defaultClient
    try {
      const response = await client.mutate({ mutation: startTimerMutation, variables: { taskId } })
      const timer = response.data.startTimer
      commit('updateOne', timer)
      commit('setTotalSeconds', { id: timer.id, totalSeconds: getters['totalSeconds'](timer.id) })
      commit('setRunningId', timer.id)
      dispatch('tick')
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
        console.log(e.graphQLErrors)
      }
    }
  },
  async pauseTimer({ state, commit, getters }, { comment, stop, entry }) {
    let client = this.app.apolloProvider.defaultClient
    if (state.runningId && !entry) {
      try {
        const response = await client.mutate({
          mutation: stopTimerMutation,
          variables: { entryId: state.runningId, stop, comment },
        })
        const timer = response.data.stopTimer
        commit('setRunningId', null)
        commit('updateOne', timer)
        commit('setTotalSeconds', { id: timer.id, totalSeconds: getters['totalSeconds'](timer.id) })
      } catch (e) {
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          console.log(e.graphQLErrors)
        }
      }
    } else {
      try {
        await client.mutate({
          mutation: updateEntryMutation,
          variables: {
            id: entry.id,
            comment: comment,
            bookDate: moment().format('YYYY-MM-DD'),
            taskId: entry.taskId,
            finished: true,
          },
        })
        commit('setRunningId', null)
        commit('updateOne', entry)
      } catch (e) {
        console.log('error', e)
        if (e.graphQLErrors && e.graphQLErrors[0]?.fieldErrors) {
          this.$refs.observerComment.setErrors(e.graphQLErrors[0].fieldErrors)
        }
      }
    }
  },
  async saveAllTimers({ state }) {
    let client = this.app.apolloProvider.defaultClient
    const entriesArray = []
    for (const [, value] of Object.entries(state.all)) {
      entriesArray.push(value)
    }

    try {
      await Promise.all(
        entriesArray.map((entry) => {
          return client.mutate({
            mutation: updateEntryMutation,
            variables: {
              id: entry.id,
              comment: 'Saved on logout',
              bookDate: moment().format('YYYY-MM-DD'),
              taskId: entry.taskId,
              finished: true,
            },
          })
        })
      )
    } catch (error) {
      console.log({ error })
    }
  },
  clearAll({ commit }) {
    commit('setAll', {
      list: {},
      ids: [],
    })
    commit('setRunningId', null)
  },
  updateSelectedDate({commit}, day) {
    commit('setSelectedDate', day)
  }
}

export const getters = {
  getSelectedDate: (state) => state.selectedDate,
  all: (state) => state.ids.map(id => state.all[id]),
  getById: (state) => (id) => state.all[id],
  getRunningId: (state) => state.runningId,
  getMyEntryByTaskId: (state, getters) => (id) => getters['all'].find((e) => e.task.id === id),
  time: (state, getters) => (id) => moment.duration(getters['totalSeconds'](id), 'seconds'),
  totalSeconds: (state, getters) => (id) => {
    const entry = getters['getById'](id)
    if (entry.timings.length) {
      let totalSeconds
      if (entry.timings.length > 1) {
        totalSeconds = entry.timings.map((e) => e.totalSeconds).reduce((a, b) => a + b)
      } else {
        totalSeconds = entry.timings[0].totalSeconds
      }
      return totalSeconds
    }
    return 0
  },
  currentTotalSeconds: (state, getters) => {
    if (state.runningId) {
      return getters['getById'](state.runningId).totalSeconds
    }
    return 0
  },
}
