<template>
  <svg width="44" height="44" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.7268 22.5C43.7268 34.4994 33.9994 44.2268 22 44.2268C10.0006 44.2268 0.27319 34.4994 0.27319 22.5C0.27319 10.5006 10.0006 0.77319 22 0.77319C33.9994 0.77319 43.7268 10.5006 43.7268 22.5Z"
      fill="#EFEFEF"
      stroke="#EEEEEE"
      stroke-width="0.54638"
    />
    <path
      d="M22.2002 24.5697C26.5286 24.5697 30.0374 21.0608 30.0374 16.7325C30.0374 12.4041 26.5286 8.89526 22.2002 8.89526C17.8719 8.89526 14.363 12.4041 14.363 16.7325C14.363 21.0608 17.8719 24.5697 22.2002 24.5697Z"
      fill="#FBFBFC"
    />
    <path
      d="M38.4447 36.9557C34.6333 41.2925 29.2693 43.9566 23.5107 44.3728C17.752 44.789 12.0607 42.9239 7.66528 39.1802L9.55227 33.9148C9.9787 32.9796 10.6516 32.1781 11.4989 31.5963C12.3462 31.0145 13.3359 30.6742 14.3619 30.6121L15.5623 30.5384L18.4248 28.0696C18.4248 28.0696 26.1376 27.7942 26.0232 27.6624L30.6951 30.4725L30.6912 30.4531L32.5278 30.5656C33.5538 30.6274 34.5436 30.9676 35.3909 31.5494C36.2383 32.1313 36.9111 32.9329 37.3374 33.8683L38.4447 36.9557Z"
      fill="#9F9F9F"
    />
    <path
      d="M30.4685 13.9836C30.5235 13.3984 30.5487 12.8109 30.544 12.2232C30.576 11.0609 30.3631 9.90489 29.919 8.8303C29.3728 7.64049 28.399 6.69972 27.191 6.19492C25.9937 5.712 24.6557 5.71317 23.4592 6.19818C22.379 5.5157 21.1788 4.97467 19.9148 4.90764C18.6508 4.84064 17.3144 5.30957 16.5355 6.34182C16.3615 6.61429 16.1639 6.87095 15.9451 7.10883C15.3189 7.68867 14.3674 7.60503 13.5351 7.48647C13.9084 7.8064 14.2818 8.12632 14.6552 8.44624C14.2034 8.56429 13.7515 8.68233 13.2996 8.80037C13.8269 9.12018 14.3542 9.43999 14.8814 9.75982C13.3507 10.6786 12.6549 12.6841 12.8387 14.5054C13.0125 16.2298 13.8451 17.8067 14.8403 19.2082C15.2373 18.3704 15.1517 17.368 15.1452 16.4244C15.1415 16.1183 15.1602 15.8124 15.201 15.5091C18.5644 16.3153 22.0787 16.8327 25.6426 15.5523L26.3578 13.8018C26.4581 13.8122 26.5586 13.8218 26.6589 13.8325L27.0732 15.5539C28.0127 15.9379 28.954 16.2734 29.8958 15.9208C29.9416 16.1867 29.9692 16.458 29.9904 16.7188C30.0712 16.4069 30.1414 16.0926 30.2046 15.7771C30.3291 15.7103 30.4483 15.6342 30.5612 15.5493C30.5839 15.0256 30.5529 14.5009 30.4685 13.9836Z"
      fill="#9F9F9F"
    />
  </svg>

</template>

<script>
export default {
  name: "AvatarIcon",
}
</script>

<style scoped>

</style>
