var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-progress",class:_vm.isFlex ? 'is-flex' : ''},[(_vm.showValue)?_c('s-number',{class:{
      'is-green':_vm.isSuccess,
      'is-orange':_vm.isWarning,
      'is-red':_vm.isDanger,
      'is-large':_vm.isLarge,
      'is-medium':_vm.isMedium,
      'is-small':_vm.isSmall,
    }},[_vm._v("\n    "+_vm._s(_vm.newValue)+" "),(_vm.showMax)?_c('span',[_vm._v("/ "+_vm._s(_vm.max))]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"progress-wrapper"},[_c('progress',{ref:"progress",class:{
      'is-success':_vm.isSuccess,
      'is-warning':_vm.isWarning,
      'is-danger':_vm.isDanger,
      'is-full':_vm.value >= _vm.max,
    },attrs:{"max":_vm.max},domProps:{"value":_vm.value}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }